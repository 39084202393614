// components/ui/faq-section.tsx
'use client';

import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Mail } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

interface FaqSectionProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
  description?: string;
  items: {
    question: string;
    answer: string;
  }[];
  contactInfo?: {
    title: string;
    description: string;
    buttonText: string;
    onContact?: () => void;
  };
}

// 1. Memoize the FaqItem component
const FaqItem = React.forwardRef<
  HTMLDivElement,
  {
    question: string;
    answer: string;
    index: number;
  }
>((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { question, answer, index } = props;

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, delay: index * 0.1 }}
      className={cn(
        'group h-fit overflow-hidden rounded-lg border transition-all duration-200',
        isOpen 
          ? 'border-zinc-300 bg-zinc-50' 
          : 'bg-background hover:bg-muted/40'
      )}
    >
      <Button
        variant="ghost"
        onClick={() => setIsOpen(!isOpen)}
        className={cn(
          "h-auto w-full justify-between px-6 py-4 hover:bg-transparent",
          isOpen && "font-medium"
        )}
      >
        <h3 className="text-left text-base text-foreground">
          {question}
        </h3>
        <motion.div
          animate={{ 
            rotate: isOpen ? 180 : 0,
            scale: isOpen ? 1.1 : 1
          }}
          transition={{ duration: 0.2 }}
          className={cn(
            "rounded-full p-1 transition-colors",
            isOpen ? "bg-zinc-200 text-zinc-700" : "text-muted-foreground"
          )}
        >
          <ChevronDown className="h-3 w-3" />
        </motion.div>
      </Button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: 'auto',
              opacity: 1,
              transition: { duration: 0.2 }
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: { duration: 0.2 }
            }}
          >
            <div className="border-t border-zinc-200 bg-white px-6 pb-4 pt-3">
              <motion.p
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                className="text-sm leading-relaxed text-muted-foreground [&_a]:text-blue-500 [&_a]:hover:text-blue-700 [&_a]:underline"
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});
FaqItem.displayName = 'FaqItem';

// 5. Memoize the main FaqSection component
const FaqSection = React.memo(React.forwardRef<HTMLElement, FaqSectionProps>(
  ({ className, title, description, items, contactInfo, ...props }, ref) => {
    // 6. Memoize the items rendering
    const renderedItems = React.useMemo(() => 
      items.map((item, index) => (
        <FaqItem
          key={index}
          question={item.question}
          answer={item.answer}
          index={index}
        />
      )),
      [items]
    );

    return (
      <section
        ref={ref}
        className={cn('w-full', className)}
        {...props}
      >
        <div className="lg:px-0 px-2">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mx-auto mb-12 max-w-2xl text-center"
          >
            <h2 className="mb-3 text-4xl font-medium">{title}</h2>
            {description && (
              <p className="text-muted-foreground">{description}</p>
            )}
          </motion.div>

          {/* FAQ Items */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {renderedItems}
          </div>

          {/* Contact Section */}
          {/* {contactInfo && (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 0.3 }}
							className="max-w-md mx-auto mt-12 p-6 rounded-lg text-center"
						>
							<div className="inline-flex items-center justify-center p-1.5 rounded-full mb-4">
								<Mail className="h-4 w-4" />
							</div>
							<p className="text-sm font-medium text-foreground mb-1">
								{contactInfo.title}
							</p>
							<p className="text-xs text-muted-foreground mb-4">
								{contactInfo.description}
							</p>
							<Button size="sm" onClick={contactInfo.onContact}>
								{contactInfo.buttonText}
							</Button>
						</motion.div>
					)} */}
        </div>
      </section>
    );
  }
));
FaqSection.displayName = 'FaqSection';

export { FaqSection };
