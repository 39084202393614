import type { Attachment } from 'ai';
import { Loader } from 'lucide-react';
import { X } from 'lucide-react';

interface PreviewAttachmentProps {
  attachment: Attachment;
  isUploading?: boolean;
  onRemove?: () => void;
}

export function PreviewAttachment({
  attachment,
  isUploading,
  onRemove,
}: PreviewAttachmentProps) {
  const { url, contentType } = attachment;

  return (
    <div className="relative">
      <div className="h-16 w-20 overflow-hidden rounded-md bg-muted">
        {contentType?.startsWith('image') && (
          <img
            src={url}
            alt="Attachment"
            className="h-full w-full object-cover"
          />
        )}

        {isUploading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader className="h-5 w-5 animate-spin text-zinc-500" />
          </div>
        )}

        {!isUploading && onRemove && (
          <button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRemove();
            }}
            className="absolute left-1 top-1 rounded-full bg-black/50 p-0.5 hover:bg-black/70"
          >
            <X className="h-3 w-3 text-white" />
          </button>
        )}
      </div>
    </div>
  );
}
