import { create } from 'zustand';
import { api } from '@/lib/axios';

interface Project {
  _id: string;
  projectName: string;
  thumbnailUrl: string;
}

interface FeaturedProject {
  _id: string;
  order: number;
  previewUrl: string;
  webProject: Project;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

interface ProjectsResponse {
  success: boolean;
  data: Project[];
  pagination: PaginationData;
}

interface PaginationData {
  totalDocs: number;
  totalPages: number;
  page: number;
  limit: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

// Static featured projects data
const FEATURED_PROJECTS: FeaturedProject[] = [
  {
    _id: '67c85df934c20b3a44965f89',
    order: 1,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/52375126e864e813082a00b9a654c197.webp',
    webProject: {
      _id: '67a0f47a18f01585b956c78f',
      projectName: 'Sci Fi Dashboard',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/52375126e864e813082a00b9a654c197.webp',
    },
    updatedAt: '2025-03-05T14:21:45.780Z',
    createdAt: '2025-03-05T14:21:45.780Z',
    __v: 0,
  },
  {
    _id: '67c85df934c20b3a44965f8d',
    order: 2,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/6301718f1ab3f2fec2eebd000321a6e3.webp',
    webProject: {
      _id: '678f7a1c75284740a495485b',
      projectName: 'Mac OS Clone',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/6301718f1ab3f2fec2eebd000321a6e3.webp',
    },
    updatedAt: '2025-03-05T14:21:45.851Z',
    createdAt: '2025-03-05T14:21:45.851Z',
    __v: 0,
  },
  {
    _id: '67c85df934c20b3a44965f91',
    order: 3,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/db5cd34ec04842e3b509eed20b61df39.webp',
    webProject: {
      _id: '67967c12c697da8a71a1832b',
      projectName: 'Finance Dashboard',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/db5cd34ec04842e3b509eed20b61df39.webp',
    },
    updatedAt: '2025-03-05T14:21:45.921Z',
    createdAt: '2025-03-05T14:21:45.921Z',
    __v: 0,
  },
  {
    _id: '67c85dfa34c20b3a44965f99',
    order: 5,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/946e9bf4202f32b3b1a12f6bb1210177.webp',
    webProject: {
      _id: '67aceaf4991d3b2933457e33',
      projectName: 'Garden Game',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/946e9bf4202f32b3b1a12f6bb1210177.webp',
    },
    updatedAt: '2025-03-05T14:21:46.083Z',
    createdAt: '2025-03-05T14:21:46.083Z',
    __v: 0,
  },
  {
    _id: '67c8902b0d508f278b21048a',
    order: 6,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/ce4809d3064f0152f4f0c534d1585681.webp',
    webProject: {
      _id: '678979f875f6a6d7600bf964',
      projectName: 'Pokemon Personality Matcher',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/ce4809d3064f0152f4f0c534d1585681.webp',
    },
    updatedAt: '2025-03-05T17:55:55.378Z',
    createdAt: '2025-03-05T17:55:55.378Z',
    __v: 0,
  },
  {
    _id: '67c8916221649b32406a6828',
    order: 7,
    previewUrl:
      'https://pre-built-images.s3.amazonaws.com/webapp-uploads/c82e0c4a47b3a466a573329a8a897c75.webp',
    webProject: {
      _id: '67873aae17e4c9f822abb146',
      projectName: 'Fortune Teller',
      thumbnailUrl:
        'https://pre-built-images.s3.amazonaws.com/webapp-uploads/c82e0c4a47b3a466a573329a8a897c75.webp',
    },
    updatedAt: '2025-03-05T18:01:06.417Z',
    createdAt: '2025-03-05T18:01:06.417Z',
    __v: 0,
  },
];

interface ProjectsStore {
  projects: Project[];
  isLoading: boolean;
  error: string | null;
  pagination: PaginationData | null;
  selectedCategory: string;
  currentPage: number;
  isInitialized: boolean;
  fetchProjects: (token: string | null) => Promise<void>;
  setSelectedCategory: (category: string) => void;
  fetchFilteredProjects: (token: string | null, page: number) => Promise<void>;
  filteredProjects: () => Project[];
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  searchProjects: (token: string | null, query: string) => Promise<void>;
  clearSearch: () => void;
}

export const useProjectsStore = create<ProjectsStore>((set, get) => ({
  projects: [],
  isLoading: true,
  error: null,
  pagination: null,
  selectedCategory: 'all',
  currentPage: 1,
  isInitialized: false,
  searchQuery: '',

  setSelectedCategory: category => {
    set({
      selectedCategory: category,
      currentPage: 1,
      ...(category === 'featured' ? { isLoading: false } : {}),
    });
  },

  fetchProjects: async token => {
    if (get().isInitialized) return;

    try {
      if (!token) {
        set({
          projects: FEATURED_PROJECTS.map(fp => fp.webProject),
          pagination: null,
          isLoading: false,
          selectedCategory: 'featured',
          isInitialized: true,
        });
        return;
      }

      const response = await api.post<ProjectsResponse>(
        '/api/web/all-projects/list',
        { page: 1, limit: 6 },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      set({
        projects: response.data.data,
        pagination: response.data.pagination,
        isLoading: false,
        selectedCategory: 'all',
        isInitialized: true,
      });
    } catch (error) {
      set({
        error: 'Failed to fetch projects',
        isLoading: false,
        isInitialized: true,
      });
    }
  },

  fetchFilteredProjects: async (token, page) => {
    if (!token) return;

    try {
      const response = await api.post<ProjectsResponse>(
        get().selectedCategory === 'deployed'
          ? '/api/web/deployed/list'
          : '/api/web/all-projects/list',
        { page, limit: 6 },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      set({
        projects: response.data.data,
        pagination: response.data.pagination,
        currentPage: page,
        isLoading: false,
      });
    } catch (error) {
      set({
        error: 'Failed to fetch projects',
        isLoading: false,
      });
    }
  },

  filteredProjects: () => {
    const { projects, selectedCategory } = get();

    if (selectedCategory === 'featured') {
      return FEATURED_PROJECTS.map(fp => fp.webProject);
    }

    return projects;
  },

  setSearchQuery: query => {
    set({
      searchQuery: query,
      ...(query === '' ? { isLoading: true } : {}),
    });
  },

  clearSearch: () => {
    set({
      searchQuery: '',
      isLoading: true, // Set loading immediately when clearing
    });
  },

  searchProjects: async (token, query) => {
    if (!token) return;

    try {
      set({ isLoading: true });
      const response = await api.post<ProjectsResponse>(
        '/api/web/search',
        {
          query: query.trim(),
          page: 1,
          limit: 6,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      set({
        projects: response.data.data,
        pagination: response.data.pagination,
        currentPage: 1,
        isLoading: false,
        error: null,
      });
    } catch (error) {
      set({
        error: 'Failed to search projects',
        isLoading: false,
        projects: [],
      });
    }
  },
}));
