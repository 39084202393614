import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogHeader,
  DialogDescription,
} from '@/components/ui/Dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import NextImage from 'next/image';
import { api } from '@/lib/axios';
import { toast } from 'sonner';
import PremiumFeatureDialog, {
  type PremiumDialogState,
} from './PremiumFeatureDialog';

interface PremiumModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  setActiveTab?: (tab: string) => void;
  featureName: string;
}

export function PremiumModal({
  open,
  onOpenChange,
  setActiveTab,
  featureName,
}: PremiumModalProps) {
  const [isFormView, setIsFormView] = useState(false);
  const [premiumDialog, setPremiumDialog] = useState<PremiumDialogState>({
    open: false,
    email: '',
    phoneNumber: '',
    emailError: '',
    phoneNumberError: '',
    isSubmitting: false,
  });

  const handleUpgradeClick = () => {
    onOpenChange(false);
    setPremiumDialog(prev => ({ ...prev, open: true }));
  };

  const handlePremiumSubmit = async () => {
    try {
      setPremiumDialog(prev => ({ ...prev, open: false }));
      if (setActiveTab) {
        setActiveTab('assets');
      }
    } catch (error) {
      console.error('Error changing tab:', error);
      toast.error('Something went wrong', {
        description: 'Please try again',
      });
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="w-[379px] overflow-hidden rounded-l-lg bg-white p-0">
          <div className="flex flex-col gap-2.5 p-5 pb-4">
            <NextImage
              src="/images/credit-card.png"
              alt="Beta features"
              width={640}
              height={640}
              className="h-[205px] w-[205px] object-contain"
              quality={100}
            />

            <DialogTitle
              className={cn(
                'font-lexend text-left text-[24px] font-[700] leading-[28px]',
                'text-[var(--colors-text-text-primary-900,#101828)]',
                '[text-decoration-skip-ink:none] [text-underline-position:from-font]',
              )}
            >
              Try Our Beta Features
            </DialogTitle>

            <p
              className={cn(
                'font-lexend text-left text-[16px] font-[400] leading-[22px]',
                'text-[var(--colors-text-text-quarterary-500,#667085)]',
                '[text-decoration-skip-ink:none] [text-underline-position:from-font]',
              )}
            >
              Be among the first to test our newest features and help shape the
              future of our product with your valuable feedback.
            </p>

            <Button
              onClick={handleUpgradeClick}
              className={cn(
                'h-[38px] w-full bg-black hover:bg-black/90',
                'flex items-center justify-center px-4',
                'font-inter text-[16px] font-[500] leading-[22px]',
                'text-[var(--Component-colors-Components-Buttons-Primary-button-primary-fg,#FFFFFF)]',
                '[text-decoration-skip-ink:none] [text-underline-position:from-font]',
                'relative rounded-md',
              )}
            >
              <span className="flex items-center gap-1.5">
                Join Beta Program
                <span style={{ fontSize: '14px' }}>➔</span>
              </span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <PremiumFeatureDialog
        state={premiumDialog}
        onStateChange={newState =>
          setPremiumDialog(prev => ({ ...prev, ...newState }))
        }
        onSubmit={handlePremiumSubmit}
        featureName={featureName}
      />
    </>
  );
}
