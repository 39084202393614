import React, { useState, useEffect, useCallback, memo } from 'react';
import { useUser, useAuth } from '@clerk/nextjs';
import { DashboardLayout } from '@/components/layouts/DashboardLayout';
import { PremiumModal } from '@/components/common/PremiumFeaturesModal';
import posthog from 'posthog-js';
import { POSTHOG_EVENTS } from '@/mixpanel/events';
import { useKnowledgeBaseStore } from '@/store/useKnowledgeBase';
import api from '@/lib/axios';
import { PricingPopup } from './ui/pricing-popup';
import { toast } from 'sonner';
import { Testimonials } from './ui/testimonials';
import { FaqSection } from './ui/faq-section';
import { useSubscription } from '@/hooks/useSubscription';
import GallerySection from './GallerySection';
import OpenaiUsageDialog from './OpenaiUsageDailog';
import LandingPageVideoSection from './LandingPageVideoSection';
import LandingPagePricingSection from './LandingPagePricingSection';
import InputSection from './LandingPageInputSection';
import type { AxiosError } from 'axios';
import { DEMO_FAQS } from '@/constants/Faqs';
import { LandingTestimonials } from '@/constants/LandingTestimonials';
import LandingPageDashboardHeader from './LandingPageDashboardHeader';
import Projects from './Projects';
import { useProjectsStore } from '@/store/useProjectsStore';

const NewDashboard = () => {
  const inputPlatform = 'sexy-web';
  const flowType = 'speed';
  const [hasObjective, setHasObjective] = useState(false);
  const [shouldRegenerateObjective, setShouldRegenerateObjective] =
    useState(false);

  const { user } = useUser();
  const email = user?.emailAddresses?.[0]?.emailAddress;

  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const showWebapp = true;

  const { reset: resetKnowledgeBase } = useKnowledgeBaseStore();

  const { getToken, isSignedIn, isLoaded } = useAuth();
  const { fetchProjects, isInitialized } = useProjectsStore();

  useEffect(() => {
    if (email) {
      posthog.setPersonPropertiesForFlags({
        email: email,
      });
    }
  }, [email]);

  const handleInputPlatformChange = (value: string) => {
    if (value === 'mobile') {
      setShowPremiumModal(true);
      return;
    }

    posthog.capture(POSTHOG_EVENTS.PLATFORM_SWITCH, {
      prompt: prompt,
      hasObjective: hasObjective,
      oldPlatform: inputPlatform,
      newPlatform: value,
    });
  };

  const handlePremiumModalClose = useCallback(() => {
    setShowPremiumModal(false);
  }, []);

  const handleUpgrade = async (billingPeriod: 'monthly' | 'annual') => {
    try {
      const token = await getToken();
      const response = await api.post<{
        url: string;
      }>(
        '/api/stripe/create-checkout-session',
        { billingPeriod },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (response.data?.url) {
        window.location.href = response.data.url;
      }
    } catch (error: unknown) {
      const axiosError = error as AxiosError<{
        success: boolean;
        message: string;
      }>;
      if (axiosError?.response?.data?.message === 'Unauthorized') {
        toast.info('Please login first!');
      } else {
        toast.error('Failed to start checkout process. Please try again.');
      }
    } finally {
    }
  };

  const handleObjectiveGenerated = useCallback((value: boolean) => {
    setHasObjective(value);
  }, []);

  useEffect(() => {
    if (shouldRegenerateObjective && !hasObjective) {
      // Reset the flag once objective generation is complete
      setShouldRegenerateObjective(false);
    }
  }, [shouldRegenerateObjective, hasObjective]);

  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    return () => {
      resetKnowledgeBase();
    };
  }, [resetKnowledgeBase]);

  const [showUsageModal, setShowUsageModal] = useState(false);
  const { isPremiumUser } = useSubscription();

  // Simplified initialization effect
  useEffect(() => {
    const initializeProjects = async () => {
      if (!isLoaded) return;
      const token = isSignedIn ? await getToken() : null;
      await fetchProjects(token);
    };

    initializeProjects();
  }, [isLoaded, isSignedIn, getToken, fetchProjects]);

  return (
    <DashboardLayout>
      <div className="flex h-[75vh] flex-col justify-center">
        <LandingPageDashboardHeader />

        <InputSection
          platform={inputPlatform}
          email={email}
          onPlatformChange={handleInputPlatformChange}
          onObjectiveGenerated={handleObjectiveGenerated}
          flowType={flowType}
          shouldRegenerateObjective={shouldRegenerateObjective}
          showWebapp={showWebapp || false}
        />
      </div>

      {/* <GallerySection email={email} /> */}
      <Projects isInitialized={isInitialized} />

      <LandingPageVideoSection isPremiumUser={isPremiumUser} />

      <Testimonials
        className="mt-20"
        maxDisplayed={100}
        testimonials={LandingTestimonials}
      />

      <LandingPagePricingSection
        isPremiumUser={isPremiumUser}
        handleUpgrade={handleUpgrade}
      />

      <FaqSection
        title="Still have doubts?"
        description="Get answers to the most burning user doubts"
        items={DEMO_FAQS}
        className="my-20"
        contactInfo={{
          title: 'Still have questions?',
          description: "We're here to help you",
          buttonText: 'Contact Support',
          onContact: () => console.log('Contact support clicked'),
        }}
      />

      <PremiumModal
        open={showPremiumModal}
        onOpenChange={handlePremiumModalClose}
        featureName="the Mobile Module"
      />
      <OpenaiUsageDialog
        showUsageModal={showUsageModal}
        setShowUsageModal={setShowUsageModal}
      />

      <PricingPopup />
    </DashboardLayout>
  );
};

export default memo(NewDashboard);
