import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/Dialog';
import { api } from '@/lib/axios';
import { toast } from 'sonner';

export interface PremiumDialogState {
  open: boolean;
  email: string;
  phoneNumber: string;
  emailError: string;
  phoneNumberError: string;
  isSubmitting: boolean;
}

interface PremiumFeatureDialogProps {
  state: PremiumDialogState;
  onStateChange: (newState: Partial<PremiumDialogState>) => void;
  onSubmit: () => Promise<void>;
  featureName?: string;
}

export default function PremiumFeatureDialog({
  state,
  onStateChange,
  onSubmit,
  featureName = 'this feature',
}: PremiumFeatureDialogProps) {
  const validatePhoneInput = (value: string) => {
    // Check if input contains invalid characters
    if (/[^\d+]/.test(value)) {
      onStateChange({
        phoneNumberError: "Only numbers and '+' are allowed",
      });
      // Return the cleaned value
      return value.replace(/[^\d+]/g, '');
    }
    // Clear error if input is valid
    if (state.phoneNumberError === "Only numbers and '+' are allowed") {
      onStateChange({ phoneNumberError: '' });
    }
    return value;
  };

  const handleSubmit = async () => {
    try {
      // Reset previous errors
      onStateChange({
        emailError: '',
        phoneNumberError: '',
        isSubmitting: true,
      });

      // Validate email with regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(state.email)) {
        onStateChange({
          emailError: 'Please enter a valid email address',
          isSubmitting: false,
        });
        return;
      }

      // Validate phone number
      if (state.phoneNumber.length < 10) {
        onStateChange({
          phoneNumberError: 'Please enter a valid phone number',
          isSubmitting: false,
        });
        return;
      }

      // Make API call
      await api.post('/users/request-premium-feature', {
        email: state.email,
        phoneNumber: state.phoneNumber,
        feature: featureName,
      });

      // Call onSubmit after successful API call
      try {
        await onSubmit();
        toast.success(`Successfully requested access to ${featureName}`, {
          description: "We'll get back to you shortly!",
        });
      } catch (error) {
        console.error('Error in onSubmit callback:', error);
        toast.error('Something went wrong', {
          description: 'Please try again later',
        });
      }

      // Reset form regardless of onSubmit success/failure
      onStateChange({
        open: false,
        email: '',
        phoneNumber: '',
        emailError: '',
        phoneNumberError: '',
        isSubmitting: false,
      });
    } catch (error) {
      console.error('Error requesting premium feature:', error);
      toast.error('Failed to submit request', {
        description: 'Please check your connection and try again',
      });
      onStateChange({ isSubmitting: false });
    }
  };

  return (
    <Dialog open={state.open} onOpenChange={open => onStateChange({ open })}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Beta Feature Access</DialogTitle>
          <DialogDescription>
            Enter your details to join the beta for {featureName}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="premium-email">Email Address</Label>
            <Input
              id="premium-email"
              type="email"
              placeholder="your@email.com"
              value={state.email}
              onChange={e =>
                onStateChange({
                  email: e.target.value,
                  emailError: '',
                })
              }
              className={state.emailError ? 'border-red-500' : ''}
            />
            {state.emailError && (
              <p className="text-sm text-red-500">{state.emailError}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="premium-phone">Phone Number</Label>
            <Input
              id="premium-phone"
              type="tel"
              placeholder="+1234567890"
              value={state.phoneNumber}
              onChange={e =>
                onStateChange({
                  phoneNumber: validatePhoneInput(e.target.value),
                })
              }
              className={state.phoneNumberError ? 'border-red-500' : ''}
            />
            {state.phoneNumberError && (
              <p className="text-sm text-red-500">{state.phoneNumberError}</p>
            )}
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <Button
            variant="ghost"
            onClick={() => onStateChange({ open: false })}
          >
            Cancel
          </Button>
          <Button
            onClick={() => void handleSubmit()}
            disabled={state.isSubmitting || !state.email || !state.phoneNumber}
          >
            {state.isSubmitting ? 'Submitting...' : 'Join Beta'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
