import { useAuth } from '@clerk/nextjs';
import { useState, useEffect } from 'react';

export function useChangelogManager(currentVersion: string) {
  const [showChangelog, setShowChangelog] = useState(false);

  // const { isSignedIn, isLoaded } = useAuth();

  // useEffect(() => {
  //   try {
  //     const viewedVersions = JSON.parse(
  //       localStorage.getItem('viewedChangelogs') || '[]',
  //     );

  //     if (!viewedVersions.includes(currentVersion) && isSignedIn && isLoaded) {
  //       setShowChangelog(true);
  //       localStorage.setItem(
  //         'viewedChangelogs',
  //         JSON.stringify([...viewedVersions, currentVersion]),
  //       );
  //     }
  //   } catch (error) {
  //     console.error('Error parsing viewed changelogs', error);
  //     localStorage.removeItem('viewedChangelogs');
  //     localStorage.setItem(
  //       'viewedChangelogs',
  //       JSON.stringify([currentVersion]),
  //     );
  //   }
  // }, [currentVersion, isSignedIn, isLoaded]);

  return {
    showChangelog,
    setShowChangelog,
    markAsSeen: () => setShowChangelog(false),
  };
}
