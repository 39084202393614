import { useUserStore } from '@/store/useUserStore';
import React, { useMemo, useState, useEffect } from 'react';
import { useProjectsStore } from '@/store/useProjectsStore';
import { useAuth } from '@clerk/nextjs';
import GallerySectionSkeleton from './GallerySectionSkeleton';
import {
  ChevronLeft,
  ChevronRight,
  Search,
  X,
  HelpCircle,
  MoreVertical,
  Trash,
  Edit,
  ExternalLink,
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { api } from '@/lib/axios';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/Dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/hooks/use-toast';

// Types for CategoryChip props
interface CategoryChipProps {
  category: string;
  label: string;
  isSelected: boolean;
  onClick: (category: string) => void;
  className?: string;
}

// Component for category chip to prevent re-renders
const CategoryChip = React.memo<CategoryChipProps>(
  ({ category, label, isSelected, onClick, className }) => (
    <button
      onClick={() => onClick(category)}
      className={`rounded-full px-4 py-2 text-sm ${
        isSelected
          ? 'bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800/50 dark:hover:bg-zinc-700/50'
          : 'hover:bg-zinc-100 dark:hover:bg-zinc-800/50'
      } text-zinc-600 transition-colors dark:text-zinc-300 ${className || ''}`}
    >
      {label}
    </button>
  ),
);

CategoryChip.displayName = 'CategoryChip';

// Memoized separator component
const Separator = React.memo(() => (
  <div className="h-6 w-px bg-zinc-200 dark:bg-zinc-700" />
));

Separator.displayName = 'Separator';

// Types for GalleryHeader props
interface GalleryHeaderProps {
  showProjectCount?: boolean;
  projectCount?: number;
  isFeatured?: boolean;
  children?: React.ReactNode;
}

// Memoized header component
const GalleryHeader = React.memo<GalleryHeaderProps>(
  ({ showProjectCount, projectCount, isFeatured, children }) => (
    <div className="mb-4 flex items-center gap-2">
      <h2 className="text-xl font-semibold text-zinc-900 dark:text-zinc-50">
        {isFeatured ? 'Featured Projects' : 'Your Projects'}
      </h2>
      {showProjectCount && (
        <span className="rounded-full bg-zinc-100 px-2 py-1 text-sm text-zinc-600 dark:bg-zinc-800/50 dark:text-zinc-300">
          {projectCount}
        </span>
      )}
      {children}
    </div>
  ),
);

GalleryHeader.displayName = 'GalleryHeader';

const truncateText = (text: string, maxChars: number) => {
  if (!text) return 'Empty Text';
  const cleanText = text.replace(/- /g, '').replace(/\n/g, ' ').trim();
  return cleanText.length > maxChars
    ? cleanText.substring(0, maxChars).trim() + '...'
    : cleanText;
};

// Add NotFound component
const NotFound = React.memo(({ searchQuery }: { searchQuery?: string }) => (
  <div className="flex flex-col items-center justify-center py-12 text-center">
    <div className="mb-4 rounded-full bg-zinc-100 p-3 dark:bg-zinc-800">
      <Search className="h-6 w-6 text-zinc-500 dark:text-zinc-400" />
    </div>
    <h3 className="mb-1 text-lg font-medium text-zinc-900 dark:text-zinc-100">
      {searchQuery
        ? `No results found for "${searchQuery}"`
        : 'No projects found'}
    </h3>
    <p className="text-sm text-zinc-500 dark:text-zinc-400">
      {searchQuery
        ? 'Try adjusting your search terms or clear the search'
        : 'Get started by creating your first project'}
    </p>
  </div>
));

NotFound.displayName = 'NotFound';

// Header skeleton component
const HeaderSkeleton = React.memo(() => (
  <div className="flex flex-col gap-2">
    <div className="h-9 w-64 animate-pulse rounded bg-muted md:h-11" />
    <div className="space-y-2">
      <div className="h-6 w-full max-w-xl animate-pulse rounded bg-muted" />
    </div>
  </div>
));

HeaderSkeleton.displayName = 'HeaderSkeleton';

// Category chips skeleton
const CategoryChipsSkeleton = React.memo(() => (
  <div className="hidden items-center gap-4 md:flex">
    {[1, 2, 3].map(i => (
      <React.Fragment key={i}>
        <div className="h-8 w-24 animate-pulse rounded-full bg-zinc-200 dark:bg-zinc-800" />
        {i < 3 && (
          <div className="h-6 w-px animate-pulse bg-zinc-200 dark:bg-zinc-700" />
        )}
      </React.Fragment>
    ))}
  </div>
));

CategoryChipsSkeleton.displayName = 'CategoryChipsSkeleton';

// Project cards skeleton
const ProjectCardsSkeleton = React.memo(() => (
  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
    {[1, 2, 3, 4, 5, 6].map(i => (
      <div
        key={i}
        className="group flex flex-col gap-3 rounded-lg border border-border bg-card p-4"
      >
        <div className="relative aspect-video animate-pulse overflow-hidden rounded-md bg-muted" />
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="h-7 w-3/4 animate-pulse rounded bg-muted" />
          </div>
        </div>
      </div>
    ))}
  </div>
));

ProjectCardsSkeleton.displayName = 'ProjectCardsSkeleton';

// Optimize search input with a separate component
const SearchInput = React.memo(() => {
  const { searchQuery, setSearchQuery, clearSearch } = useProjectsStore();

  return (
    <div className="flex w-full items-center gap-2 sm:w-auto">
      <div className="relative w-full sm:w-64">
        <div className="pointer-events-none absolute inset-y-0 left-3 flex items-center">
          <Search className="h-4 w-4 text-zinc-500" />
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search projects..."
          className="w-full rounded-full border border-zinc-200 bg-white py-2 pl-10 pr-4 text-sm text-zinc-900 transition-all placeholder:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-300 dark:border-zinc-800 dark:bg-zinc-900 dark:text-zinc-100 dark:placeholder:text-zinc-400 dark:focus:ring-zinc-700"
        />
        {searchQuery && (
          <button
            onClick={() => clearSearch()}
            className="absolute inset-y-0 right-3 flex items-center"
          >
            <X className="h-4 w-4 text-zinc-500 hover:text-zinc-700 dark:hover:text-zinc-300" />
          </button>
        )}
      </div>

      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>
            <button className="flex items-center justify-center rounded-full p-1.5 transition-colors duration-200 hover:bg-zinc-100 dark:hover:bg-zinc-800">
              <HelpCircle className="h-4 w-4 text-zinc-400 hover:text-zinc-600 dark:hover:text-zinc-300" />
            </button>
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            align="end"
            className="max-w-[220px] rounded-lg border border-zinc-200 bg-white px-3 py-2 text-zinc-900 shadow-lg animate-in fade-in-0 zoom-in-95"
          >
            <div className="flex items-start gap-2">
              <Search className="mt-0.5 h-4 w-4 flex-shrink-0 text-zinc-500" />
              <div>
                <p className="mb-1 font-medium text-zinc-900">Project Search</p>
                <p className="text-xs leading-relaxed text-zinc-600">
                  Search through project names and prompts across all your
                  projects
                </p>
              </div>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
});

SearchInput.displayName = 'SearchInput';

// Add Project interface
interface Project {
  _id: string;
  projectName: string;
  thumbnailUrl: string;
}

// Add PaginationData interface
interface PaginationData {
  totalDocs: number;
  totalPages: number;
  page: number;
  limit: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

// Add proper type for ProjectCard props
interface ProjectCardProps {
  project: Project;
  prefetchWorkspace: (id: string) => void;
  onProjectUpdate?: () => void;
}

// Optimize project card with a separate component
const ProjectCard = React.memo<ProjectCardProps>(
  ({ project, prefetchWorkspace, onProjectUpdate }) => {
    const { getToken } = useAuth();
    const { toast } = useToast();
    const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [newName, setNewName] = useState(project.projectName);
    const [isLoading, setIsLoading] = useState(false);

    // Get the selected category from the store to check if we're in featured mode
    const { selectedCategory } = useProjectsStore();
    const isFeatured = selectedCategory === 'featured';

    // Handle opening in new tab
    const handleOpenNewTab = (e: React.MouseEvent) => {
      e.stopPropagation();
      window.open(`/workspace/${project._id}`, '_blank');
    };

    // Handle rename dialog
    const handleRenameClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setNewName(project.projectName);
      setIsRenameDialogOpen(true);
    };

    // Handle rename submission
    const handleRenameSubmit = async (e?: React.FormEvent) => {
      // Prevent form submission if event is provided
      if (e) {
        e.preventDefault();
      }

      if (!newName.trim()) return;

      try {
        setIsLoading(true);
        const token = await getToken();

        const response = await api.post(
          '/projects/rename',
          {
            projectId: project._id,
            newName: newName.trim(),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (response.data.success) {
          toast({
            title: 'Project renamed',
            description: 'Your project has been renamed successfully.',
          });

          // Refresh projects list
          if (onProjectUpdate) onProjectUpdate();
          setIsRenameDialogOpen(false);
        } else {
          toast({
            title: 'Failed to rename project',
            description:
              response.data.message ||
              'An error occurred while renaming the project.',
            variant: 'destructive',
          });
        }
      } catch (error) {
        console.error('Error renaming project:', error);
        toast({
          title: 'Failed to rename project',
          description: 'An error occurred while renaming the project.',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Handle delete dialog
    const handleDeleteClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsDeleteDialogOpen(true);
    };

    // Handle delete submission
    const handleDeleteSubmit = async () => {
      try {
        setIsLoading(true);
        const token = await getToken();

        const response = await api.post(
          '/projects/delete',
          {
            projectId: project._id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (response.data.success) {
          toast({
            title: 'Project deleted',
            description: 'Your project has been deleted successfully.',
          });

          // Refresh projects list
          if (onProjectUpdate) onProjectUpdate();
          setIsDeleteDialogOpen(false);
        } else {
          toast({
            title: 'Failed to delete project',
            description:
              response.data.message ||
              'An error occurred while deleting the project.',
            variant: 'destructive',
          });
        }
      } catch (error) {
        console.error('Error deleting project:', error);
        toast({
          title: 'Failed to delete project',
          description: 'An error occurred while deleting the project.',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div className="group flex flex-col gap-3 rounded-lg border border-border bg-card p-4 transition-all hover:shadow-lg">
        <Link
          href={`/workspace/${project._id}`}
          className="block"
          onMouseEnter={() => prefetchWorkspace(project._id)}
        >
          <div className="relative aspect-video overflow-hidden rounded-md bg-muted">
            <img
              src={project.thumbnailUrl || '/images/wireframe.png'}
              alt={project.projectName}
              className="h-full w-full object-cover transition-transform group-hover:scale-105"
              loading="lazy"
            />
          </div>
        </Link>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Link href={`/workspace/${project._id}`} className="mr-2 flex-1">
              <h3 className="text-xl font-medium tracking-tight">
                {truncateText(project.projectName, 20)}
              </h3>
            </Link>

            {/* Only show hamburger menu for non-featured projects */}
            {!isFeatured && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button
                    className="rounded-md p-1 transition-colors hover:bg-zinc-100 dark:hover:bg-zinc-800"
                    onClick={e => e.stopPropagation()}
                  >
                    <MoreVertical className="h-4 w-4 text-zinc-500" />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-48">
                  <DropdownMenuItem onClick={handleRenameClick}>
                    <Edit className="mr-2 h-4 w-4" />
                    <span>Rename</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleOpenNewTab}>
                    <ExternalLink className="mr-2 h-4 w-4" />
                    <span>Open in new tab</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={handleDeleteClick}
                    className="text-red-600 focus:text-red-700 dark:text-red-400 dark:focus:text-red-300"
                  >
                    <Trash className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}

            {/* For featured projects, only show open in new tab */}
            {isFeatured && (
              <button
                onClick={handleOpenNewTab}
                className="rounded-md p-1 transition-colors hover:bg-zinc-100 dark:hover:bg-zinc-800"
              >
                <ExternalLink className="h-4 w-4 text-zinc-500" />
              </button>
            )}
          </div>
        </div>

        {/* Rename Dialog */}
        <Dialog open={isRenameDialogOpen} onOpenChange={setIsRenameDialogOpen}>
          <DialogContent className="sm:max-w-md">
            <form onSubmit={handleRenameSubmit}>
              <DialogHeader>
                <DialogTitle>Rename Project</DialogTitle>
                <DialogDescription>
                  Enter a new name for your project.
                </DialogDescription>
              </DialogHeader>
              <div className="flex items-center space-y-4 py-2">
                <Input
                  value={newName}
                  onChange={e => setNewName(e.target.value)}
                  placeholder="Project name"
                  className="w-full"
                />
              </div>
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setIsRenameDialogOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoading || !newName.trim()}>
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Delete Project</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete "{project.projectName}"? This
                action cannot be undone.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsDeleteDialogOpen(false)}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="destructive"
                onClick={handleDeleteSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Deleting...' : 'Delete'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

ProjectCard.displayName = 'ProjectCard';

// Add proper type for PaginationControls
const PaginationControls = React.memo(() => {
  const { pagination, currentPage, selectedCategory } = useProjectsStore();
  const { getToken, isSignedIn } = useAuth();
  const { isInitialized } = useProjectsStore();

  // Function to handle page changes
  const handlePageChange = async (newPage: number) => {
    if (!isSignedIn || !isInitialized) return;
    const token = await getToken();
    await useProjectsStore.getState().fetchFilteredProjects(token, newPage);
  };

  // Only hide pagination for featured projects or when there's only 1 page
  if (
    selectedCategory === 'featured' ||
    !pagination ||
    pagination.totalPages <= 1
  )
    return null;

  const pages = Array.from(
    { length: Math.min(5, pagination.totalPages) },
    (_, i) => {
      if (pagination.totalPages <= 5) return i + 1;

      if (currentPage <= 3) return i + 1;
      if (currentPage >= pagination.totalPages - 2) {
        return pagination.totalPages - 4 + i;
      }
      return currentPage - 2 + i;
    },
  );

  return (
    <div className="mt-8 flex items-center justify-center gap-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={!pagination.hasPrevPage}
        className={`flex h-8 w-8 items-center justify-center rounded-md ${
          pagination.hasPrevPage
            ? 'bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800 dark:hover:bg-zinc-700'
            : 'cursor-not-allowed opacity-50'
        }`}
        aria-label="Previous page"
      >
        <ChevronLeft className="h-4 w-4" />
      </button>

      {pages.map(page => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`flex h-8 w-8 items-center justify-center rounded-md ${
            currentPage === page
              ? 'bg-zinc-800 text-white dark:bg-zinc-100 dark:text-black'
              : 'bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800 dark:hover:bg-zinc-700'
          }`}
          aria-label={`Page ${page}`}
          aria-current={currentPage === page ? 'page' : undefined}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={!pagination.hasNextPage}
        className={`flex h-8 w-8 items-center justify-center rounded-md ${
          pagination.hasNextPage
            ? 'bg-zinc-100 hover:bg-zinc-200 dark:bg-zinc-800 dark:hover:bg-zinc-700'
            : 'cursor-not-allowed opacity-50'
        }`}
        aria-label="Next page"
      >
        <ChevronRight className="h-4 w-4" />
      </button>
    </div>
  );
});

PaginationControls.displayName = 'PaginationControls';

// Add proper type for CategoryNavigation props
interface CategoryNavigationProps {
  selectedCategory: string;
  handleCategoryClick: (category: string) => void;
}

// Category navigation component
const CategoryNavigation = React.memo<CategoryNavigationProps>(
  ({ selectedCategory, handleCategoryClick }) => (
    <div className="-mx-2 flex items-center gap-2 overflow-x-auto px-2 pb-2 sm:mx-0 sm:gap-4 sm:px-0 sm:pb-0">
      <CategoryChip
        category="all"
        label="All Projects"
        isSelected={selectedCategory === 'all'}
        onClick={handleCategoryClick}
        className="whitespace-nowrap hover:bg-secondary"
      />
      <Separator />
      <CategoryChip
        category="deployed"
        label="Deployed"
        isSelected={selectedCategory === 'deployed'}
        onClick={handleCategoryClick}
        className="whitespace-nowrap hover:bg-secondary"
      />
      <Separator />
      <CategoryChip
        category="featured"
        label="Featured"
        isSelected={selectedCategory === 'featured'}
        onClick={handleCategoryClick}
        className="whitespace-nowrap hover:bg-secondary"
      />
    </div>
  ),
);

CategoryNavigation.displayName = 'CategoryNavigation';

// Update the DeployedZeroState component with a better icon
const DeployedZeroState = React.memo(() => (
  <div className="flex flex-col items-center justify-center py-12 text-center">
    <div className="mb-4 rounded-full bg-zinc-100 p-3 dark:bg-zinc-800">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-6 w-6 text-zinc-500 dark:text-zinc-400"
      >
        {/* Cloud upload icon - better represents deployment */}
        <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242"></path>
        <path d="M12 12v9"></path>
        <path d="m16 16-4-4-4 4"></path>
      </svg>
    </div>
    <h3 className="mb-1 text-lg font-medium text-zinc-900 dark:text-zinc-100">
      No deployed projects yet
    </h3>
    <p className="max-w-md text-sm text-zinc-500 dark:text-zinc-400">
      Deploy your projects to make them accessible to others. Deployed projects
      will appear here.
    </p>
  </div>
));

DeployedZeroState.displayName = 'DeployedZeroState';

// Project grid component - update to handle deployed zero state and empty state after deletion
const ProjectGrid = React.memo(() => {
  const {
    filteredProjects,
    isLoading,
    isInitialized,
    selectedCategory,
    searchQuery,
    fetchFilteredProjects,
    projects,
  } = useProjectsStore();
  const { getToken, isSignedIn } = useAuth();
  const isClearing = React.useRef(false);
  const router = useRouter();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

  const prefetchWorkspace = React.useCallback(
    (id: string) => {
      router.prefetch(`/workspace/${id}`);
    },
    [router],
  );

  // Add a function to refresh projects after updates
  const handleProjectUpdate = React.useCallback(async () => {
    if (!isSignedIn || !isInitialized) return;
    const token = await getToken();
    await fetchFilteredProjects(token, 1);
    setHasAttemptedFetch(true);
  }, [isSignedIn, isInitialized, getToken, fetchFilteredProjects]);

  // Set hasAttemptedFetch to true after initial load
  useEffect(() => {
    if (!isLoading && isInitialized) {
      setHasAttemptedFetch(true);
    }
  }, [isLoading, isInitialized]);

  // Don't show loading for featured category
  if (
    (!isInitialized || isLoading) &&
    selectedCategory !== 'featured' &&
    !hasAttemptedFetch
  ) {
    return <ProjectCardsSkeleton />;
  }

  const filteredProjectsList = filteredProjects();

  // Show deployed zero state when in deployed category with no projects
  if (filteredProjectsList.length === 0) {
    if (selectedCategory === 'deployed') {
      return <DeployedZeroState />;
    }

    if (!isClearing.current && searchQuery) {
      return <NotFound searchQuery={searchQuery} />;
    }

    // If we've attempted to fetch and there are no projects, show empty state
    if (hasAttemptedFetch || projects.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center py-12 text-center">
          <div className="mb-4 rounded-full bg-zinc-100 p-3 dark:bg-zinc-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-6 w-6 text-zinc-500 dark:text-zinc-400"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="12" y1="18" x2="12" y2="12"></line>
              <line x1="9" y1="15" x2="15" y2="15"></line>
            </svg>
          </div>
          <h3 className="mb-1 text-lg font-medium text-zinc-900 dark:text-zinc-100">
            No projects found
          </h3>
          <p className="max-w-md text-sm text-zinc-500 dark:text-zinc-400">
            Get started by creating your first project
          </p>
        </div>
      );
    }

    return <ProjectCardsSkeleton />;
  }

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 md:gap-8 lg:grid-cols-3">
      {filteredProjectsList.map(project => (
        <ProjectCard
          key={project._id}
          project={project}
          prefetchWorkspace={prefetchWorkspace}
          onProjectUpdate={handleProjectUpdate}
        />
      ))}
    </div>
  );
});

ProjectGrid.displayName = 'ProjectGrid';

// Add proper type for ProjectsHeader props
interface ProjectsHeaderProps {
  isLoaded: boolean;
  isSignedIn: boolean | undefined;
  selectedCategory: string;
}

// Header component
const ProjectsHeader = React.memo<ProjectsHeaderProps>(
  ({ isLoaded, isSignedIn, selectedCategory }) => {
    if (!isLoaded) {
      return <HeaderSkeleton />;
    }

    return (
      <div className="flex flex-col gap-2">
        <h2 className="text-3xl font-medium tracking-tighter md:text-4xl">
          {!isSignedIn || selectedCategory === 'featured'
            ? 'Featured Projects'
            : 'Your Projects'}
        </h2>
        <p className="max-w-xl text-lg leading-relaxed tracking-tight text-muted-foreground">
          {!isSignedIn || selectedCategory === 'featured'
            ? 'Explore our featured projects and get inspired'
            : 'Manage and track all your projects in one place'}
        </p>
      </div>
    );
  },
);

ProjectsHeader.displayName = 'ProjectsHeader';

// Add this debounce hook if you don't have it
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Update the interface for Projects component
interface ProjectsProps {
  isInitialized: boolean;
}

// Main Projects component - update to switch to "featured" when user logs out
const Projects = ({ isInitialized }: ProjectsProps) => {
  const {
    error,
    selectedCategory,
    setSelectedCategory,
    filteredProjects,
    searchQuery,
    clearSearch,
    projects,
    isLoading,
  } = useProjectsStore();
  const { getToken, isSignedIn, isLoaded } = useAuth();
  const isFirstRender = React.useRef(true);
  const hasSetDefaultCategory = React.useRef(false);
  const previousCategory = React.useRef(selectedCategory);
  const previousSignedInState = React.useRef(isSignedIn);

  // Track if we've ever had projects to prevent UI flickering
  const hadProjectsRef = React.useRef(false);

  // Debounce search query
  const debouncedSearch = useDebounce(searchQuery, 500);

  // Add a ref to track if we're in a clearing state
  const isClearing = React.useRef(false);

  // Check if user has any projects
  const hasProjects = projects.length > 0;

  // Update the hadProjects ref if we ever have projects
  React.useEffect(() => {
    if (hasProjects) {
      hadProjectsRef.current = true;
    }
  }, [hasProjects]);

  // Handle login/logout state changes
  React.useEffect(() => {
    // If user logs in and has projects, switch to "all" category
    if (
      isSignedIn &&
      !previousSignedInState.current &&
      isInitialized &&
      hasProjects
    ) {
      setSelectedCategory('all');
    }

    // If user logs out, switch to "featured" category
    if (!isSignedIn && previousSignedInState.current && isInitialized) {
      useProjectsStore.setState({ isLoading: false });
      setSelectedCategory('featured');
    }

    // Update the previous signed-in state
    previousSignedInState.current = isSignedIn;
  }, [isSignedIn, isInitialized, hasProjects, setSelectedCategory]);

  // Set default category on initial load
  React.useEffect(() => {
    if (isInitialized && !hasSetDefaultCategory.current) {
      if (!isSignedIn || !hasProjects) {
        // For logged out users or users with no projects, show featured
        useProjectsStore.setState({ isLoading: false });
        setSelectedCategory('featured');
      } else {
        // For logged in users with projects, show all projects
        setSelectedCategory('all');
      }
      hasSetDefaultCategory.current = true;
    }
  }, [isSignedIn, isInitialized, hasProjects, setSelectedCategory]);

  // Handle category changes - prevent flickering when switching to featured
  React.useEffect(() => {
    const fetchFiltered = async () => {
      if (!isSignedIn || !isInitialized) return;

      // Skip the first render since parent already fetched the data
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      // Skip if the category hasn't actually changed (prevents infinite loading)
      if (
        previousCategory.current === selectedCategory &&
        previousCategory.current !== ''
      ) {
        return;
      }

      // For featured category, set loading false immediately before any async operations
      if (selectedCategory === 'featured') {
        // Only update if we're coming from a different category
        if (previousCategory.current !== 'featured') {
          useProjectsStore.setState({ isLoading: false });
        }
        previousCategory.current = selectedCategory;
        return;
      }

      // For non-featured categories, set loading true before fetching
      if (previousCategory.current === 'featured') {
        useProjectsStore.setState({ isLoading: true });
      }

      previousCategory.current = selectedCategory;
      const token = await getToken();
      await useProjectsStore.getState().fetchFilteredProjects(token, 1);
    };

    // Only fetch if there's no search query
    if (!searchQuery) {
      fetchFiltered();
    }
  }, [selectedCategory, isSignedIn, isInitialized, searchQuery, getToken]);

  // Handle search
  React.useEffect(() => {
    const handleSearch = async () => {
      if (!isSignedIn || !isInitialized) return;

      // Don't show loading for featured category
      if (selectedCategory === 'featured') {
        return;
      }

      useProjectsStore.setState({ isLoading: true });

      const token = await getToken();

      if (!debouncedSearch.trim()) {
        isClearing.current = true;
        await useProjectsStore.getState().fetchFilteredProjects(token, 1);
        setTimeout(() => {
          isClearing.current = false;
        }, 100);
      } else {
        isClearing.current = false;
        await useProjectsStore
          .getState()
          .searchProjects(token, debouncedSearch);
      }
    };

    handleSearch();
  }, [debouncedSearch, isSignedIn, isInitialized, selectedCategory, getToken]);

  const handleCategoryClick = React.useCallback(
    (category: string) => {
      // Don't do anything if clicking the already selected category
      if (category === selectedCategory) {
        return;
      }

      // For featured category, set loading false immediately to prevent flickering
      if (category === 'featured') {
        useProjectsStore.setState({ isLoading: false });
      } else if (selectedCategory === 'featured') {
        // If switching from featured to another category, set loading true immediately
        useProjectsStore.setState({ isLoading: true });
      }

      clearSearch(); // Use the store's clearSearch instead of manually setting
      setSelectedCategory(category);
    },
    [clearSearch, setSelectedCategory, selectedCategory],
  );

  // Render error state if there's an error
  if (error) {
    return (
      <div className="flex items-center justify-center py-12">
        <div className="text-red-600 dark:text-red-400">{error}</div>
      </div>
    );
  }

  // Determine if we should show the category navigation
  // Show if user is signed in and loaded, AND either:
  // 1. User currently has projects, OR
  // 2. User had projects at some point (prevents disappearing when switching categories), OR
  // 3. User is not on featured tab (always show when on other tabs)
  const showCategoryNav =
    isLoaded &&
    isSignedIn &&
    (hasProjects || hadProjectsRef.current || selectedCategory !== 'featured');

  return (
    <div className="w-full px-2 sm:px-4">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-col gap-6 sm:gap-8 md:gap-10">
          {/* Header Section */}
          <ProjectsHeader
            isLoaded={isLoaded}
            isSignedIn={isSignedIn}
            selectedCategory={selectedCategory}
          />

          {/* Category Chips with Conditional Search */}
          {showCategoryNav && (
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
              <CategoryNavigation
                selectedCategory={selectedCategory}
                handleCategoryClick={handleCategoryClick}
              />

              {/* Only show search for 'all' category */}
              {selectedCategory === 'all' && <SearchInput />}
            </div>
          )}

          {/* Use a wrapper div to maintain consistent height during transitions */}
          <div className="min-h-[300px]">
            <ProjectGrid />
          </div>

          {/* Pagination - Only show if we have projects */}
          {isSignedIn &&
            selectedCategory !== 'featured' &&
            filteredProjects().length > 0 && <PaginationControls />}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Projects);
