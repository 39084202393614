import { Lock } from 'lucide-react';

export const ReadOnlyBadge = () => {
  return (
    <div className="flex items-center gap-2 rounded-full border border-gray-200 bg-gray-100 px-3 py-1.5 text-gray-700 shadow-sm">
      <Lock size={14} className="text-gray-500" />
      <span className="text-sm font-medium">Read only</span>
    </div>
  );
};
