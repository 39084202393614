'use client';

import { useState } from 'react';
import Image from 'next/image';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/testimonial-button';
import { Card } from '@/components/ui/testimonial-card';
import { Icons } from '@/components/ui/testimonial-icons';

interface Testimonial {
  image: string;
  name: string;
  username: string;
  text: string;
  social: string;
}

interface TestimonialsProps {
  testimonials: Testimonial[];
  className?: string;
  title?: string;
  description?: string;
  maxDisplayed?: number;
}

export function Testimonials({
  testimonials,
  className,
  title = 'Loved by thousands of dreamers',
  description = 'See why builders love Creatr, and why they swear by it.',
  maxDisplayed = 6,
}: TestimonialsProps) {
  const [showAll, setShowAll] = useState(false);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
  };

  return (
    <div className={className}>
      <div className="flex flex-col items-center justify-center pt-5">
        <div className="mb-8 flex flex-col gap-5">
          <h2 className="text-center text-4xl font-medium">{title}</h2>
          <p className="text-center text-muted-foreground">
            {description.split('<br />').map((line, i) => (
              <span key={i}>
                {line}
                {i !== description.split('<br />').length - 1 && <br />}
              </span>
            ))}
          </p>
        </div>
      </div>

      <div className="relative">
        <div
          className={cn(
            'grid grid-cols-1 justify-items-center gap-5 md:grid-cols-2 lg:grid-cols-3',
            !showAll &&
              testimonials.length > maxDisplayed &&
              'max-h-[720px] overflow-hidden',
          )}
        >
          {testimonials
            .slice(0, showAll ? undefined : maxDisplayed)
            .map((testimonial, index) => (
              <Card
                key={index}
                className="relative h-auto w-80 rounded-xl border-border bg-card p-5 pb-2"
              >
                <div className="flex items-center">
                  <Image
                    src={testimonial.image}
                    alt={testimonial.name}
                    width={50}
                    height={50}
                    className="rounded-full"
                  />
                  <div className="flex flex-col pl-4">
                    <span className="text-base font-semibold">
                      {testimonial.name}
                    </span>
                    <span className="text-sm text-muted-foreground">
                      {testimonial.username}
                    </span>
                  </div>
                </div>
                <div className="mb-5 mt-5">
                  <p className="font text-foreground">{testimonial.text}</p>
                </div>
                <button className="absolute right-4 top-4 transition-opacity hover:opacity-80">
                  <Icons.twitter className="h-4 w-4" aria-hidden="true" />
                </button>
              </Card>
            ))}
        </div>

        {testimonials.length > maxDisplayed && !showAll && (
          <>
            <div className="absolute bottom-0 left-0 h-20 w-full bg-gradient-to-t from-background to-transparent" />
            <div className="absolute bottom-10 left-1/2 z-20 -translate-x-1/2 transform">
              <Button variant="secondary" onClick={() => setShowAll(true)}>
                Load More
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
