import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from './ui/Dialog';
import {
  ArrowRight,
  CloudLightning,
  DollarSign,
  FolderGit2,
  type LucideProps,
  Zap,
} from 'lucide-react';
import Link from 'next/link';
import { ScrollArea } from './ui/scroll-area';
import type { IProxyUsage } from '@/types/db';
import { useAuth, useUser } from '@clerk/nextjs';
import api from '@/lib/axios';

type Props = {
  showUsageModal: boolean;
  setShowUsageModal: (a: boolean) => void;
};

const OpenaiUsageDialog = ({ showUsageModal, setShowUsageModal }: Props) => {
  const [isLoadingUsage, setIsLoadingUsage] = useState(false);
  const [usageData, setUsageData] = useState<{
    projects: IProxyUsage[];
    projectsSummary: {
      projectId: string; // or possibly ObjectId depending on your MongoDB setup
      totalTokensUsed: number;
      totalPromptTokens: number;
      totalCompletionTokens: number;
      totalCost: number;
      totalPromptCost: number;
      totalCompletionCost: number;
    }[];
  } | null>(null);
  const { getToken } = useAuth();
  const { user } = useUser();

  const email = user?.emailAddresses?.[0]?.emailAddress;
  useEffect(() => {
    if (email) {
      const fetchUsageData = async () => {
        setIsLoadingUsage(true);
        try {
          const token = await getToken();
          const response = await api.post<{
            success: boolean;
            data: {
              projects: IProxyUsage[];
              projectsSummary: {
                projectId: string; // or possibly ObjectId depending on your MongoDB setup
                totalTokensUsed: number;
                totalPromptTokens: number;
                totalCompletionTokens: number;
                totalCost: number;
                totalPromptCost: number;
                totalCompletionCost: number;
              }[];
            };
          }>(
            '/api/proxy-usage/projects',
            {
              email: email,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const data = response.data;
          if (data.success) {
            setUsageData(data.data);
          }
        } catch (error) {
          console.error('Error fetching usage data:', error);
        } finally {
          setIsLoadingUsage(false);
        }
      };
      fetchUsageData().catch(e => {
        console.error(e);
      });
    }
  }, [email, getToken]);

  return (
    <Dialog open={showUsageModal} onOpenChange={setShowUsageModal}>
      <DialogContent className="max-h-[90vh] max-w-4xl overflow-y-scroll">
        <DialogHeader className="mb-4">
          <DialogTitle className="bg-gradient-to-br from-blue-600 to-purple-600 bg-clip-text text-xl font-bold text-transparent">
            OpenAI Credits Usage
          </DialogTitle>
          <DialogDescription className="space-y-4">
            <p className="text-sm">
              Track OpenAI credits consumption across your projects. Usage is
              calculated based on tokens used for prompts and completions.
            </p>
            <div className="rounded-lg border border-blue-200 bg-blue-50/50 p-4 dark:border-blue-900 dark:bg-blue-950/30">
              <div className="space-y-2 text-sm">
                <div className="font-semibold text-blue-700 dark:text-blue-300">
                  💡 Pro Tip:
                </div>
                Connect your projects with OpenAI through our Integrations
                feature during project creation or in project settings.
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>

        {isLoadingUsage ? (
          <div className="flex justify-center py-16">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-primary border-t-transparent" />
          </div>
        ) : !usageData?.projects?.length ? (
          <EmptyState />
        ) : (
          <div className="space-y-8 py-6 pt-0">
            {usageData?.projectsSummary && (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                <SummaryCard
                  icon={CloudLightning}
                  title="Total Tokens Used"
                  value={
                    usageData.projectsSummary[0]?.totalTokensUsed.toLocaleString() ??
                    ''
                  }
                  gradient="border-blue-200 bg-gradient-to-br from-blue-50 to-blue-100/50 dark:border-blue-900 dark:from-blue-950/50 dark:to-blue-900/30"
                />
                <SummaryCard
                  icon={DollarSign}
                  title="Total Cost"
                  value={`$${usageData.projectsSummary[0]?.totalCost.toFixed(4)}`}
                  gradient="border-purple-200 bg-gradient-to-br from-purple-50 to-purple-100/50 dark:border-purple-900 dark:from-purple-950/50 dark:to-purple-900/30"
                />
                <SummaryCard
                  icon={FolderGit2}
                  title="Total Projects"
                  value={`${usageData?.projects?.length || 0}`}
                  gradient="border-pink-200 bg-gradient-to-br from-pink-50 to-pink-100/50 dark:border-pink-900 dark:from-pink-950/50 dark:to-pink-900/30"
                />
              </div>
            )}

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-semibold">Project Details</h3>
                <p className="flex items-center gap-2 text-sm text-muted-foreground">
                  Click to view details <ArrowRight className="h-4 w-4" />
                </p>
              </div>

              <ScrollArea className="h-full max-h-[400px]">
                <div className="grid grid-cols-2 gap-4">
                  {usageData?.projects?.map(project => (
                    <ProjectCard key={project.projectId} project={project} />
                  ))}
                </div>
              </ScrollArea>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

function EmptyState() {
  return (
    <div className="px-6 py-12">
      <div className="mx-auto max-w-2xl space-y-8 text-center">
        <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-br from-blue-500 to-purple-500">
          <Zap className="h-10 w-10 text-white" />
        </div>
        <div className="space-y-4">
          <h3 className="text-2xl font-bold">No OpenAI Usage Data Yet</h3>
          <p className="text-muted-foreground">
            Connect your projects with OpenAI to start tracking usage:
          </p>

          <div className="space-y-4">
            <div className="rounded-lg border bg-card p-4 shadow-sm">
              <div className="flex gap-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900">
                  <span className="font-bold text-blue-600 dark:text-blue-400">
                    1
                  </span>
                </div>
                <div className="flex-1">
                  <p className="font-semibold">New Projects</p>
                  <p className="text-sm text-muted-foreground">
                    Enable OpenAI integration during project creation
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-lg border bg-card p-4 shadow-sm">
              <div className="flex gap-4">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900">
                  <span className="font-bold text-purple-600 dark:text-purple-400">
                    2
                  </span>
                </div>
                <div className="flex-1">
                  <p className="font-semibold">Existing Projects</p>
                  <p className="text-sm text-muted-foreground">
                    Use the Integrations button in project settings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SummaryCard({
  icon: Icon,
  title,
  value,
  gradient,
}: {
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
  title: string;
  value: string;
  gradient: string;
}) {
  return (
    <div className={`rounded-xl border p-6 ${gradient}`}>
      <div className="flex items-center gap-3">
        <Icon className="h-5 w-5" />
        <p className="text-sm font-medium">{title}</p>
      </div>
      <p className="mt-3 text-2xl font-bold">{value}</p>
    </div>
  );
}

function ProjectCard({ project }: { project: IProxyUsage }) {
  return (
    <Link
      href={`/workspace/${project.projectId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="group flex rounded-xl border bg-card p-4 transition-all hover:border-gray-100 hover:shadow">
        <div className="flex-1 space-y-4">
          <div className="flex items-start justify-between">
            <div>
              <h4 className="font-semibold group-hover:text-primary">
                Project <span>{project.projectId}</span>
              </h4>
              <p className="text-xs text-muted-foreground">
                Created {new Date(project.created).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="mb-1 text-sm text-muted-foreground">
                Prompt Tokens
              </p>
              <p className="text-lg font-semibold">
                {project.promptTokens.toLocaleString()}
              </p>
            </div>
            <div>
              <p className="mb-1 text-sm text-muted-foreground">
                Completion Tokens
              </p>
              <p className="text-lg font-semibold">
                {project.completionTokens.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-zinc-500 dark:text-zinc-400">
                Usage Distribution
              </span>
              <span className="font-medium text-zinc-900 dark:text-zinc-100">
                {Math.round((project.promptTokens / project.totalTokens) * 100)}
                % Prompt
              </span>
            </div>
            <div className="h-2 overflow-hidden rounded-full bg-zinc-100 dark:bg-zinc-800">
              <div
                className="h-full bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300"
                style={{
                  width: `${(project?.promptTokens / project?.totalTokens) * 100}%`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default OpenaiUsageDialog;
