import React from 'react';
import { cn } from '@/lib/utils';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { INTEGRATIONS_LIST } from './multimodal-input';
import { useWebappStore } from '@/store/useWebappData';
import { useClerk, useUser } from '@clerk/nextjs';
import { SiOpenai } from 'react-icons/si';

const IntegrationsButton = ({
  className = '',
  forceOpen = false,
}: {
  className?: string;
  forceOpen?: boolean;
}) => {
  const [open, setOpen] = React.useState(forceOpen);
  const { activeIntegrations, setActiveIntegrations } = useWebappStore();
  const { isSignedIn } = useUser();

  const { openSignIn } = useClerk();

  React.useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  return (
    <Popover open={open} onOpenChange={a => setOpen(a)}>
      <PopoverTrigger asChild>
        <button
          onClick={() => setOpen(!open)}
          className={cn(
            'flex w-fit items-center gap-1 rounded-lg border bg-[#F8FAFB] px-2 py-1 text-xs text-body-text transition-all hover:bg-[#dfe1e2]/50 hover:shadow-sm',
            className,
          )}
        >
          <SiOpenai size={12} />
          <span className="hidden md:inline">Open AI</span>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-4" align="start">
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="font-semibold">Integrations (Experimental)</h3>
            <p className="text-xs text-muted-foreground">
              Easily add functionality to your webapp. This feature is
              experimental and will be refined in the upcoming days with
              additional security considerations.
            </p>
          </div>
          <div className="space-y-2">
            {INTEGRATIONS_LIST.map(integration => (
              <div
                key={integration.name}
                className="flex cursor-pointer items-center gap-3 rounded-md border p-3 transition-colors hover:bg-gray-50"
                onClick={() => {
                  if (!isSignedIn) {
                    openSignIn({
                      withSignUp: true,
                      forceRedirectUrl: `/`,
                      appearance: {
                        layout: {
                          socialButtonsPlacement: 'bottom',
                          socialButtonsVariant: 'blockButton',
                          unsafe_disableDevelopmentModeWarnings: true,
                        },
                        elements: {
                          formButtonPrimary:
                            'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                          card: '!shadow-none border-none ',
                          afterButtonsNonLogin: 'hidden',
                          footer: 'hidden',
                          cardBox: '!shadow-none',
                          modalContent: 'h-screen !items-center',
                          modalBackdrop: '!items-center',
                        },
                      },
                    });
                    return;
                  }
                  if (activeIntegrations.includes(integration.id)) {
                    setOpen(false);
                    return;
                  }
                  setActiveIntegrations([
                    ...activeIntegrations,
                    integration.id,
                  ]);
                  setOpen(false);
                  console.log(`Selected ${integration.name}`);
                }}
              >
                <div
                  className={cn(
                    'flex h-10 w-10 items-center justify-center rounded-md border bg-[#2D2D2D] shadow-sm',
                    integration.className,
                  )}
                >
                  <img
                    src={integration.icon}
                    alt={integration.name}
                    className="h-6 w-6 object-contain"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-medium">
                    {integration.name}
                  </span>
                  <span className="text-xs text-muted-foreground">
                    {integration.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default IntegrationsButton;
