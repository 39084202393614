import { memo } from 'react';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';
import { Laptop } from 'lucide-react';
import Image from 'next/image';
import DashboardInput from './DashBoardInput';
import type { Platform, FlowType } from './DashBoardInput/types';

const InputSection = memo(
  ({
    platform,
    email,
    onPlatformChange,
    flowType,
    shouldRegenerateObjective,
    onObjectiveGenerated,
    showWebapp,
  }: {
    platform: Platform;
    email?: string;
    onPlatformChange: (value: string) => void;
    flowType: FlowType;
    shouldRegenerateObjective?: boolean;
    onObjectiveGenerated: (value: boolean) => void;
    showWebapp: boolean;
  }) => {
    return (
      <div className="mx-auto mb-8 w-full max-w-3xl px-2 sm:px-0">
        <div className="mb-2 flex justify-start">
          <Tabs value={platform} onValueChange={onPlatformChange}>
            <TabsList className="h-8 bg-zinc-100 dark:bg-zinc-800">
              {showWebapp && (
                <TabsTrigger
                  value="sexy-web"
                  className="px-3 text-sm text-zinc-600 data-[state=active]:bg-white data-[state=active]:text-zinc-900 dark:text-zinc-400 dark:data-[state=active]:bg-zinc-900 dark:data-[state=active]:text-zinc-50"
                >
                  <Laptop className="mr-0.5 h-4 w-4 opacity-60 data-[state=active]:opacity-100 dark:hidden" />
                  Web
                </TabsTrigger>
              )}
              <TabsTrigger
                value="mobile"
                className="px-3 text-sm text-zinc-600 data-[state=active]:bg-white data-[state=active]:text-zinc-900 dark:text-zinc-400 dark:data-[state=active]:bg-zinc-900 dark:data-[state=active]:text-zinc-50"
              >
                <Image
                  src="/icons/mobile.svg"
                  alt="Creatr Icon"
                  width={16}
                  height={16}
                  className="mr-0.5 h-4 w-4 opacity-60 data-[state=active]:opacity-100 dark:hidden"
                />
                Mobile
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <DashboardInput
          platform={platform}
          userEmail={email}
          onObjectiveGenerated={onObjectiveGenerated}
          flowType={flowType}
          shouldRegenerateObjective={shouldRegenerateObjective}
        />
      </div>
    );
  },
);

InputSection.displayName = 'InputSection';
export default InputSection;
