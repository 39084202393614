import React from 'react';
import { AuroraBackground } from '@/components/ui/aurora-background';
import { cn } from '@/lib/utils';

import { Navbar } from '../ui/navbar';
import { FooterDemo } from '../ui/footer-section';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  return (
    <div className="space-y-2 p-2">
      <Navbar />
      <div className="relative h-full w-full overflow-hidden rounded-xl border border-b bg-blue-50/40">
        {/* <AuroraBackground> */}
        {/*   <></> */}
        {/* </AuroraBackground> */}
        <div className={cn('flex min-h-full flex-col')}>
          <div className={cn('relative z-20 mx-auto w-full max-w-5xl')}>
            {children}
          </div>
        </div>
      </div>

      <FooterDemo />
    </div>
  );
};
