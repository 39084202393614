import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Sparkles, Loader2, ArrowUp, ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import posthog from 'posthog-js';
import { POSTHOG_EVENTS } from '@/mixpanel/events';
import { type Platform } from '../types';
import { useClerk } from '@clerk/nextjs';

interface SubmitSectionProps {
  objective: string;
  isGenerating: boolean;
  isSubmitting: boolean;
  isPolling?: boolean;
  prompt: string;
  onGenerate: () => void;
  onSubmit: () => Promise<void>;
  platform: Platform;
  flowType: 'single-flow' | 'single-screen';
  variant?: 'standalone' | 'embedded';
  hasGeneratedOnce: boolean;
  error?: string | null;
}

export interface SubmitSectionRef {
  triggerClick: () => void;
  resetState: () => void;
}

export const SubmitSection = forwardRef<SubmitSectionRef, SubmitSectionProps>(
  (
    {
      objective,
      isGenerating,
      isSubmitting,
      isPolling,
      prompt,
      onGenerate,
      onSubmit,
      platform,
      flowType,
      variant = 'standalone',
      error,
    },
    ref,
  ) => {
    const isDisabled =
      isGenerating || isPolling || isSubmitting || prompt.trim() === '';

    const handleClick = async () => {
      if (isDisabled) return;

      try {
        await onSubmit();
      } catch (error) {
        console.error('Submit error:', error);
        // Error handling is done in parent component
      }
    };

    useImperativeHandle(ref, () => ({
      triggerClick: handleClick,
    }));

    const getButtonIcon = () => {
      if (isGenerating || isPolling || isSubmitting) {
        return <Loader2 className="h-4 w-4 animate-spin" />;
      }
      return <ArrowRight className="h-5 w-5 text-white" />;
    };

    return (
      <Button
        ref={ref}
        onClick={handleClick}
        disabled={isDisabled}
        className="aspect-square h-8 w-8 gap-2 rounded-lg bg-[#FF6321] shadow-none"
        variant={'default'}
      >
        {getButtonIcon()}
      </Button>
    );
  },
);

SubmitSection.displayName = 'SubmitSection';
