export const LandingTestimonials = [
  {
    image:
      'https://pbs.twimg.com/profile_images/1682791243275747329/TtH7JCi__400x400.jpg',
    text: "Creatr has transformed my workflow! Its coding capabilities are impressive, letting me focus on higher-level development. My productivity has soared, and I'm building better projects. Highly recommend!",
    name: 'Joe',
    username: '@huahinjoe',
    social: 'https://i.imgur.com/VRtqhGC.png',
  },
  {
    image:
      'https://pbs.twimg.com/profile_images/1846749225914146816/rzsYoLVn_400x400.jpg',
    text: 'Creatr empowers me to focus on design instead of coding. It transforms my ideas into functional code effortlessly, allowing me to unleash my creativity. Plus, integrating Supabase is just a few clicks away! Come check it out!',
    name: 'John Paul',
    username: '@accuratetlm13',
    social: 'https://i.imgur.com/VRtqhGC.png',
  },
  {
    image:
      'https://pbs.twimg.com/profile_images/1890047106544762880/trUA2CFY_400x400.jpg',
    text: "Creatr lets me focus on what I do best—creating. No more getting stuck in the weeds of coding. Whether it's a quick mockup or a full-fledged app, I can build and launch faster than ever.",
    name: 'Adam',
    username: '@BlackveilLtd_',
    social: 'https://i.imgur.com/VRtqhGC.png',
  },
];
