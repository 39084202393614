import { memo } from 'react';
import { Cover } from './ui/cover';

const LandingPageDashboardHeader = memo(() => (
  <h1 className="relative z-20 mx-auto max-w-7xl bg-gradient-to-b from-zinc-900 to-zinc-800 bg-clip-text py-6 text-center text-3xl font-semibold text-transparent dark:from-zinc-200 dark:to-zinc-400 md:text-4xl lg:text-6xl">
    Create Products Without{' '}
    <span className="whitespace-nowrap">
      Any <Cover>Limits</Cover>
    </span>
  </h1>
));

LandingPageDashboardHeader.displayName = 'LandingPageDashboardHeader';

export default LandingPageDashboardHeader;
