import { POSTHOG_EVENTS } from '@/mixpanel/events';
import posthog from 'posthog-js';
import { type Platform } from '../types';
import { UserPlus2 } from 'lucide-react';

interface Suggestion {
  text: string;
  difficulty: 'Basic' | 'Intermediate' | 'Advanced';
  image?: string;
  name: string;
}

export const PROMPT_SUGGESTIONS: Record<Platform, Suggestion[]> = {
  mobile: [
    {
      difficulty: 'Basic',
      text: 'Create a login/signup flow for my food delivery platform',
      name: 'Login/Signup Flow',
    },
    {
      difficulty: 'Basic',
      text: 'Build a simple todo list app with add, edit, and delete functionality',
      name: 'Todo List App',
    },
    {
      difficulty: 'Intermediate',
      text: 'Create a social media profile page with photo grid and stories',
      name: 'Social Media Profile',
    },
    {
      difficulty: 'Advanced',
      text: 'Build an e-commerce app with shopping cart and checkout flow',
      name: 'E-commerce App',
    },
  ],
  web: [
    {
      difficulty: 'Basic',
      text: 'Create a kanban board with drag and drop functionality',
      name: 'Kanban Board',
    },
    {
      difficulty: 'Basic',
      text: 'Create a simple analytics dashboard with basic charts',
      name: 'Analytics Dashboard',
    },
    {
      difficulty: 'Intermediate',
      text: 'Design a blog with featured post and topic tabs',
      name: 'Blog Design',
    },
    {
      difficulty: 'Advanced',
      text: 'Create a finance dashboard with charts and data tables',
      name: 'Finance Dashboard',
    },
  ],
  component: [
    {
      difficulty: 'Basic',
      text: 'Create a version history timeline component with commit messages, preview links, and timestamps. Include rollback functionality, diff previews, and status badges. Add animations for version transitions.',
      name: 'Version History',
    },
    {
      difficulty: 'Intermediate',
      text: 'Build a GitHub-style pull request diff viewer with expandable code blocks, syntax highlighting, and inline comments. Include file tree navigation, suggestion mode, and resolved thread tracking.',
      name: 'Diff Viewer',
    },
    {
      difficulty: 'Intermediate',
      text: 'Create a Discord-style message component with emoji reactions, thread reply counter, and message actions. Include markdown support, attachment previews, and edit history.',
      name: 'Chat Message',
    },
    {
      difficulty: 'Advanced',
      text: 'Create a command palette (⌘K) with fuzzy search, keyboard navigation, and dynamic suggestions. Include recent/pinned items, categorized results, and customizable hotkeys.',
      name: 'Command Bar',
    },
  ],
  'sexy-web': [
    {
      difficulty: 'Basic',
      text: 'Create a kanban board with drag and drop functionality',
      name: 'Kanban Board',
    },
    {
      difficulty: 'Basic',
      text: 'Create a simple analytics dashboard with basic charts',
      name: 'Analytics Dashboard',
    },
    {
      difficulty: 'Intermediate',
      text: 'Design a blog with featured post and topic tabs',
      name: 'Blog Design',
    },
    {
      difficulty: 'Advanced',
      text: 'Create a finance dashboard with charts and data tables',
      name: 'Finance Dashboard',
    },
  ],
} as const;

interface PromptSuggestionsProps {
  platform: Platform;
  flowType: string;
  onSelect: (prompt: string) => void;
}

export const PromptSuggestions = ({
  platform,
  onSelect,
  flowType,
}: PromptSuggestionsProps) => {
  return (
    <div className="mx-auto w-full max-w-3xl">
      <div className="my-3 flex flex-col justify-center gap-2 sm:flex-row sm:gap-3">
        {PROMPT_SUGGESTIONS[platform].map((suggestion, index) => (
          <button
            key={index}
            onClick={() => {
              onSelect(suggestion.text);
              posthog.capture(POSTHOG_EVENTS.EXAMPLE_PROMPT_CLICK, {
                prompt: suggestion.text,
                flowType: flowType,
                platform: platform,
              });
            }}
            className="flex w-full items-center justify-center rounded-xl border border-gray-200 bg-gray-50 p-2 text-sm text-gray-700 transition-colors hover:bg-gray-100 sm:w-fit sm:justify-between sm:rounded-full sm:p-1 sm:px-3"
          >
            {suggestion.name}
          </button>
        ))}
      </div>
    </div>
  );
};
