'use client';
import React from 'react';
import OldLandingPage from '@/components/OldLandingPage';
import { useChangelogManager } from '@/hooks/useChangeLogs';
import ChangeLog from '@/components/ChangeLog';

const NewDashboard = () => {
  const { showChangelog, setShowChangelog } = useChangelogManager('0.1.0');

  return (
    <div>
      <ChangeLog
        isOpen={showChangelog}
        onClose={() => setShowChangelog(false)}
        version="0.1.0"
      />
      <OldLandingPage />
    </div>
  );
};

export default NewDashboard;
