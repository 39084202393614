import { useState, useCallback } from 'react';
import { api } from '@/lib/axios';
import { enqueueSnackbar } from 'notistack';
import { motion, AnimatePresence } from 'framer-motion';
import { type Platform } from '../types';

// Type Definitions
export type FlowType = 'single-flow' | 'single-screen';
export type ProjectType =
  | 'webapp'
  | 'webapp-screen'
  | 'design'
  | 'single-design';

export interface ObjectivePayload {
  userPrompt: string;
  isSingle: boolean;
  type: ProjectType;
  singleProjectType: 'flow' | 'screen';
}

export interface ObjectiveItem {
  label: string;
  content: string;
}

export interface ApiObjectiveResponse {
  businessObjective: ObjectiveItem;
  functionalRequirements: ObjectiveItem;
  primaryObjective: ObjectiveItem;
  primaryPersona: ObjectiveItem;
  targetAudience: ObjectiveItem;
  [key: string]: ObjectiveItem;
}

export interface Objectives {
  [key: string]: ObjectiveItem;
  objective: ObjectiveItem;
}

interface UseObjectiveGenerationProps {
  platform: Platform;
  flowType: FlowType;
}

export const useObjectiveGeneration = ({
  platform,
  flowType,
}: UseObjectiveGenerationProps) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [objective, setObjective] = useState('');
  const [words, setWords] = useState<
    Array<{ text: string; isNewline: boolean }>
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [structuredObjectives, setStructuredObjectives] =
    useState<Objectives | null>(null);

  const handleObjectiveChange = (newText: string) => {
    setObjective(newText);
    const lines = newText.split('\n');
    const newWords: Array<{ text: string; isNewline: boolean }> = [];

    lines.forEach((line, lineIndex) => {
      const lineWords = line.split(' ');
      lineWords.forEach((word, wordIndex) => {
        if (word.trim()) {
          newWords.push({ text: word.trim(), isNewline: false });
          if (wordIndex < lineWords.length - 1) {
            newWords.push({ text: '', isNewline: false });
          }
        }
      });
      if (lineIndex < lines.length - 1) {
        newWords.push({ text: '', isNewline: true });
      }
    });
    setWords(newWords);
  };

  const generateObjective = async (prompt: string) => {
    if (isGenerating || !prompt.trim()) return;

    setIsGenerating(true);
    setObjective('');
    setWords([]);

    try {
      const projectType: ProjectType =
        platform === 'web'
          ? flowType === 'single-flow'
            ? 'webapp'
            : 'webapp-screen'
          : flowType === 'single-flow'
            ? 'design'
            : 'single-design';

      const payload: ObjectivePayload = {
        userPrompt: `Project: ${prompt}`,
        isSingle: true,
        type: projectType,
        singleProjectType: flowType === 'single-flow' ? 'flow' : 'screen',
      };

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:4000'}/projects/autofillObjective`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let lastProcessedLength = 0;

      if (!reader) return;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode the chunk and add it to buffer
        const chunk = decoder.decode(value, { stream: true });
        if (chunk === 'emptycreatr') return;

        buffer += chunk;

        // Only process new content
        const newContent = buffer.slice(lastProcessedLength);
        if (!newContent) continue;

        // Update the processed length
        lastProcessedLength = buffer.length;

        // Process only the new words
        const newWords = newContent.split(/(\s+)/);

        newWords.forEach(word => {
          if (word.includes('\n')) {
            // Handle newlines
            const parts = word.split('\n');
            parts.forEach((part, index) => {
              if (part.trim()) {
                setWords(prev => [
                  ...prev,
                  { text: part.trim(), isNewline: false },
                ]);
              }
              if (index < parts.length - 1) {
                setWords(prev => [...prev, { text: '', isNewline: true }]);
              }
            });
          } else if (word.trim()) {
            setWords(prev => [
              ...prev,
              { text: word.trim(), isNewline: false },
            ]);
          }
        });

        setObjective(buffer);
      }
    } catch (error) {
      console.error('Error generating objective:', error);
      enqueueSnackbar('Failed to generate objective', { variant: 'error' });
    } finally {
      setIsGenerating(false);
    }
  };

  const getStructuredObjectives = async (prompt: string) => {
    try {
      const response = await api.post<{ objective: ApiObjectiveResponse }>(
        '/projects/getAutofill',
        {
          prompt,
          objective,
        },
      );

      if (response.data.objective) {
        const transformedObjectives: Objectives = {
          objective: {
            label: 'Primary Objective',
            content: response.data.objective.primaryObjective.content,
          },
          ...response.data.objective,
        };

        setStructuredObjectives(transformedObjectives);
        return transformedObjectives;
      }
      return null;
    } catch (error) {
      console.error('Error getting structured objectives:', error);
      enqueueSnackbar('Failed to get objectives', { variant: 'error' });
      return null;
    }
  };

  return {
    isGenerating,
    objective,
    generateObjective,
    setObjective: handleObjectiveChange,
    words,
    isEditing,
    setIsEditing,
    structuredObjectives,
    getStructuredObjectives,
  };
};
