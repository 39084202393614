import React from 'react';
import PricingSection from './PricingSection';

type Props = {
  isPremiumUser: boolean;
  handleUpgrade: (billingPeriod: 'monthly' | 'annual') => void;
};

const LandingPagePricingSection = ({ isPremiumUser, handleUpgrade }: Props) => {
  if (isPremiumUser) return null;
  return (
    <div className="mt-20">
      <div className="mb-8 flex flex-col items-center justify-center">
        <h2 className="text-center text-4xl font-medium">Choose Your Plan</h2>
        <p className="text-center text-muted-foreground">
          Select the perfect plan for your needs and start building amazing web
          apps today
        </p>
      </div>
      <PricingSection handleUpgrade={handleUpgrade} />
    </div>
  );
};

export default LandingPagePricingSection;
