import { useState } from 'react';
import { api } from '@/lib/axios';
import { enqueueSnackbar } from 'notistack';
import { type FlowType, type Platform, type ProjectType } from '../types';
import { useRouter } from 'next/router';
import { POSTHOG_EVENTS } from '@/mixpanel/events';
import posthog from 'posthog-js';
import { useAuth } from '@clerk/nextjs';
import { INTEGRATIONS_LIST } from '@/components/workspace/multimodal-input';
import { useWebappStore } from '@/store/useWebappData';
import { useEditsStore } from '@/store/useEditsStore';
import { usePricingStore } from '@/store/usePricingStore';

interface ProjectCreationPayload {
  prompt: string;
  objective: string;
  projectName: string;
  email?: string;
  type: ProjectType;
  platform: string;
  isPublic?: boolean;
  parameters?: string;
  singleProjectType?: 'flow' | 'screen';
}

interface SubscriptionLimitError {
  type: 'SUBSCRIPTION_LIMIT';
  message: string;
  limits: {
    remaining: number;
    total: number;
  };
  upgrade: {
    monthly: {
      amount: number;
      interval: string;
    };
    annual: {
      amount: number;
      interval: string;
      savings: string;
    };
  };
}

interface UseProjectCreationProps {
  platform: Platform;
  flowType: FlowType;
  userEmail?: string;
  singleProjectType?: 'flow' | 'screen';
  onSubscriptionLimitHit?: (error: SubscriptionLimitError) => void;
}

export const useProjectCreation = ({
  platform,
  flowType,
  userEmail,
  singleProjectType,
  onSubscriptionLimitHit,
}: UseProjectCreationProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const [subscriptionLimits, setSubscriptionLimits] =
    useState<SubscriptionLimitError | null>(null);
  const router = useRouter();
  const { getToken } = useAuth();
  const { setData } = useWebappStore();
  const setEditsInfo = useEditsStore(state => state.setEditsInfo);
  const showPricing = usePricingStore(state => state.showPricing);

  const shouldUseInterviewFlow =
    platform === 'web' ||
    (platform === 'mobile' && flowType === 'single-screen');

  const getProjectType = (): ProjectType => {
    if (platform === 'web') {
      return flowType === 'single-flow' ? 'webapp' : 'webapp-screen';
    }
    return flowType === 'single-flow' ? 'design' : 'single-design';
  };

  const createProject = async ({
    prompt,
    objective,
    projectName,
    isPublic = true,
    isInitialSubmit = true,
    knowledgeBase,
    attachments,
    integrations = [],
  }: {
    prompt: string;
    objective: string;
    projectName: string;
    isPublic?: boolean;
    isInitialSubmit?: boolean;
    knowledgeBase?: string;
    attachments?: any[];
    integrations?: (typeof INTEGRATIONS_LIST)[0][];
  }) => {
    if (!userEmail) {
      enqueueSnackbar('User email is required', { variant: 'error' });
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const type = getProjectType();
      const basePayload: ProjectCreationPayload = {
        prompt,
        objective,
        projectName,
        email: userEmail,
        type,
        platform,
        isPublic,
      };

      let response;

      if (platform === 'web') {
        const webPayload = {
          companyDescription: prompt,
          flowDescription: objective,
          email: userEmail,
          type: type,
          projectName: projectName,
          isPublic: isPublic,
        };

        const endpoint =
          flowType === 'single-flow'
            ? '/webapp/complete-webapp'
            : '/webappv2/createWebapp';

        response = await api.post(endpoint, webPayload);

        if (response.data?.webappId) {
          if (!shouldUseInterviewFlow || !isInitialSubmit) {
            router.push(`/loading-screen/${response.data.webappId}`);
          }
          posthog.capture(POSTHOG_EVENTS.CREATE_NEW_PROJECT, {
            prompt: prompt,
            objective: objective,
            type: flowType,
            platform: platform,
            success: true,
          });
          return { success: true, data: { id: response.data.webappId } };
        }
      } else if (platform === 'sexy-web' || platform === 'component') {
        const token = await getToken();
        const payload = {
          prompt: prompt,
          type: flowType,
          knowledgeBase: knowledgeBase,
          experimental_attachments: attachments?.map(attachment => ({
            url: attachment.url,
            name: attachment.name,
            type: attachment.contentType,
          })),
          integrations: integrations,
          platform: platform === 'sexy-web' ? 'web' : platform,
        };

        posthog.capture(POSTHOG_EVENTS.CREATE_NEW_PROJECT, {
          prompt: prompt,
          type: flowType,
          platform: platform,
          knowledgeBase: knowledgeBase,
          success: true,
        });
        const endpoint =
          platform === 'component'
            ? '/api/webComponent/create'
            : '/api/web/create';

        response = await api.post(endpoint, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (
          response.data?.data?._id &&
          response.data?.data?.type !== 'quality'
        ) {
          const mappedPlatform = platform === 'sexy-web' ? 'web' : platform;

          setData({
            ...response.data.data,
            platform: mappedPlatform,
          });

          // Include platform in query params when redirecting
          router.push({
            pathname: `/workspace/${response.data.data._id}`,
            query: { platform: mappedPlatform },
          });
        } else {
          router.push(`/web-loading/${response.data.data._id}`);
        }
        return { success: true, data: {} };
      } else {
        posthog.capture(POSTHOG_EVENTS.CREATE_NEW_PROJECT, {
          prompt: prompt,
          objective: objective,
          type: flowType,
          platform: platform,
          success: true,
        });
        // Handle mobile platform cases
        if (platform === 'mobile') {
          if (flowType === 'single-flow') {
            // For single-flow, get autofill data
            const autofillResponse = await api.post('/projects/getAutofill', {
              prompt,
              objective,
            });

            const parametersObject = {
              type: 'design',
              inquiry: autofillResponse.data?.objective
                ? {
                    primaryObjective:
                      autofillResponse.data.objective.primaryObjective?.content,
                    businessObjective:
                      autofillResponse.data.objective.businessObjective
                        ?.content,
                    functionalRequirements:
                      autofillResponse.data.objective.functionalRequirements
                        ?.content,
                    primaryPersona:
                      autofillResponse.data.objective.primaryPersona?.content,
                    targetAudience:
                      autofillResponse.data.objective.targetAudience?.content,
                  }
                : null,
              dsName: null,
            };

            basePayload.parameters = JSON.stringify(parametersObject);
            basePayload.singleProjectType = 'flow';
          } else if (flowType === 'single-screen') {
            // For single-screen
            basePayload.parameters = JSON.stringify({
              type: 'single-design',
              inquiry: {},
              dsName: null,
            });
            basePayload.singleProjectType = 'screen';
          }
        }

        response = await api.post('/projects/createProject', basePayload);

        if (response.data?.project?.id) {
          if (!shouldUseInterviewFlow || !isInitialSubmit) {
            router.push(`/loading-screen/${response.data.project.id}`);
          }
          return { success: true, data: { id: response.data.project.id } };
        }
      }

      if (response.data?.subscription?.editsInfo) {
        setEditsInfo(response.data.subscription.editsInfo);
      }

      return { success: true, data: response.data };
    } catch (error: any) {
      // Track error in Posthog
      posthog.capture(POSTHOG_EVENTS.CREATE_NEW_PROJECT, {
        prompt: prompt,
        objective: objective,
        type: flowType,
        platform: platform,
        success: false,
        error: error,
      });

      // Check if this is a subscription limit error
      if (error?.response?.data?.type === 'SUBSCRIPTION_LIMIT') {
        const limitError: SubscriptionLimitError = error.response.data;

        showPricing('project_creation', {
          limits: limitError.limits,
          upgrade: limitError.upgrade,
        });

        // Call the callback if provided
        if (onSubscriptionLimitHit) {
          onSubscriptionLimitHit(limitError);
        }

        setError(limitError.message);
        setIsSubmitting(false);
        return {
          success: false,
          error: limitError.message,
          type: 'SUBSCRIPTION_LIMIT',
          limits: limitError.limits,
          upgrade: limitError.upgrade,
        };
      }

      // Handle project limit exceeded error
      if (
        error?.response?.data?.message?.includes(
          'Project creation limit exceeded',
        )
      ) {
        const limitError: SubscriptionLimitError = {
          type: 'SUBSCRIPTION_LIMIT',
          message: error.response.data.message,
          limits: {
            remaining: 0,
            total: 5, // Free plan project limit
          },
          upgrade: {
            monthly: {
              amount: 89,
              interval: 'month',
            },
            annual: {
              amount: 59,
              interval: 'year',
              savings: '33%',
            },
          },
        };

        showPricing('project_creation', {
          limits: limitError.limits,
          upgrade: limitError.upgrade,
        });

        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
          autoHideDuration: 10000,
          style: { maxWidth: '500px' },
        });
      }

      // Handle credits exhausted error
      if (error?.response?.data?.error?.startsWith('Credits Exhausted')) {
        enqueueSnackbar('Credits exhausted', { variant: 'error' });
      }

      // Only reset isSubmitting on error
      setIsSubmitting(false);

      // Return error state
      return {
        success: false,
        error: error?.response?.data?.message || 'Failed to create project',
      };
    }
  };

  return {
    isSubmitting,
    error,
    createProject,
    showPricingPopup,
    setShowPricingPopup,
    subscriptionLimits,
  };
};
