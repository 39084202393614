import { motion } from 'framer-motion';
import {
  Github,
  Twitter,
  Linkedin,
  Mail,
  Target,
  TwitterIcon,
} from 'lucide-react';
import Link from 'next/link';
import { toggleChatwoot } from '../ChatwootWidget';
import { FaTiktok, FaXTwitter } from 'react-icons/fa6';
import { FaDiscord, FaYoutube } from 'react-icons/fa';
import { Separator } from './separator';
import { CREATR_DISCORD } from '@/constants/Links';

const FooterDemo = () => {
  const currentYear = new Date().getFullYear();

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="relative rounded-xl border border-t border-border border-gray-200 bg-white"
    >
      <div className="mx-auto max-w-6xl px-6 py-12">
        <div className="flex flex-col gap-8 md:flex-row md:justify-between">
          {/* Logo and Social Section - Full width on mobile */}
          <div className="flex w-full flex-col md:w-auto">
            {/* Main content area */}
            <div className="w-full md:w-auto">
              <div className="flex h-full flex-col gap-4">
                {/* Top section with logo and tagline */}
                <div className="flex flex-1 flex-col justify-start gap-2">
                  <h1 className="text-xl font-bold">Creatr</h1>
                  <p className="text-sm text-gray-700">
                    Everyone&apos;s a builder.
                  </p>
                </div>
                <div className="h-[1px] w-full bg-[#EEEEEE]"></div>

                {/* Bottom section with social icons */}
                <div className="flex items-center gap-4 text-gray-500">
                  {/* X/Twitter logo */}
                  <Link href={'https://x.com/getcreatr'} target="_blank">
                    <div className="relative">
                      <FaXTwitter size={24} />
                    </div>
                  </Link>

                  {/* Discord logo */}
                  <Link href={CREATR_DISCORD} target="_blank">
                    <div className="relative">
                      <FaDiscord size={28} />
                    </div>
                  </Link>

                  {/* YouTube logo */}
                  <Link
                    href={'https://www.youtube.com/@getcreatrxyz'}
                    target="_blank"
                  >
                    <div className="relative">
                      <FaYoutube size={28} />
                    </div>
                  </Link>

                  {/* TikTok logo */}
                  <Link
                    href={'https://www.tiktok.com/@getcreatr'}
                    target="_blank"
                  >
                    <div className="relative">
                      <FaTiktok size={20} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Links Section - Grid on mobile, flex on desktop */}
          <div className="grid grid-cols-2 gap-8 md:flex md:gap-20">
            {/* Product Section */}
            <div className="">
              <h4 className="mb-4 text-sm font-semibold text-gray-900">
                Product
              </h4>
              <ul className="space-y-3">
                {[
                  {
                    name: 'News',
                    href: 'https://x.com/getcreatr',
                    target: '_blank',
                  },
                  // { name: 'Roadmap', href: '/roadmap', target: undefined },
                ].map(item => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      target={item.target}
                      className="text-sm text-gray-500 transition-colors hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Resources Section */}
            <div className="">
              <h4 className="mb-4 text-sm font-semibold text-gray-900">
                Resources
              </h4>
              <ul className="space-y-3">
                {[
                  {
                    name: 'How to use creatr',
                    href: 'https://www.youtube.com/watch?v=4VUBzeUuksM',
                    target: '_blank',
                  },
                  {
                    name: 'Share Feedback',
                    href: 'https://creatr.featurebase.app/',
                    target: '_blank',
                  },
                ].map(item => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      target={item.target}
                      className="text-sm text-gray-500 transition-colors hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    onClick={toggleChatwoot}
                    className="text-sm text-gray-500 transition-colors hover:text-gray-900"
                  >
                    Support
                  </button>
                </li>
              </ul>
            </div>

            {/* Legal Section */}
            <div className="col-span-2 md:col-span-1">
              <h4 className="mb-4 text-sm font-semibold text-gray-900">
                Legal
              </h4>
              <ul className="space-y-3">
                {[
                  { name: 'Privacy Policy', href: '/privacy' },
                  { name: 'Terms & Conditions', href: '/terms' },
                ].map(item => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className="text-sm text-gray-500 transition-colors hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export { FooterDemo };
