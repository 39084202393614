import { CREATR_DISCORD } from "./Links";

export const DEMO_FAQS = [
  {
    question: 'What is Creatr?',
    answer:
      'Creatr is an AI-powered platform for building functional web apps and landing pages without coding. Describe your idea in simple language, and Creatr generates the code.',
  },
  {
    question: 'What does Creatr\'s free plan include?',
    answer:
      'You can build up to 5 projects and make 500 total edits—completely free.',
  },
  {
    question: 'What stack does Creatr build with?',
    answer:
      "Creatr generates Next.js + Tailwind CSS frontends and integrates with Supabase for data and auth.",
  },
  {
    question: 'Who owns the code created with Creatr?',
    answer:
      'You do. You can download or sync your code to GitHub anytime.',
  },
  {
    question: 'Benefits of upgrading to Creatr Premium?',
    answer:
      'Unlimited projects and unlimited edits—no more worries about running out of credits.',
  },
  {
    question: 'Can I connect external services?',
    answer:
      'Yes. Integrate any API or service to power your app\'s features.',
  },
  {
    question: 'Is there support or a community?',
    answer:
      `<a href="${CREATR_DISCORD}" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700 underline">Join our community</a> for help and feedback. Premium users get priority support.`,
  },
  {
    question: 'How do I deploy my app?',
    answer:
      'Export the code and deploy via Vercel, Netlify, or any preferred hosting environment.',
  },
  {
    question: 'Do I need coding experience?',
    answer:
      'Not at all. Creatr is beginner-friendly but offers full code control for advanced users by letting them download or sync with Github.',
  },
  {
    question: 'What if I hit my free plan limits?',
    answer:
      'You can still view existing projects but must upgrade to create more projects. You cannot delete past projects to get new project slots.',
  },
];
