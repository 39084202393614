import React from 'react';
import { HeroVideoDialog } from './ui/hero-video';

type Props = { isPremiumUser: boolean };

const LandingPageVideoSection = ({ isPremiumUser }: Props) => {
  if (isPremiumUser) return null;
  return (
    <div className="relative mt-20">
      <div className="mb-8 flex flex-col items-center justify-center pt-5">
        <h2 className="text-center text-4xl font-medium">Watch it in action</h2>
        <p className="text-center text-muted-foreground">
          See how builders are making the most out of Creatr
        </p>
      </div>

      <HeroVideoDialog
        className="block dark:hidden"
        animationStyle="from-center"
        videoSrc="https://www.youtube.com/watch?v=4VUBzeUuksM"
        thumbnailSrc="https://img.youtube.com/vi/4VUBzeUuksM/maxresdefault.jpg"
        thumbnailAlt="Hero Video"
      />
      {/* <HeroVideoDialog */}
      {/*   className="hidden dark:block" */}
      {/*   animationStyle="from-center" */}
      {/*   videoSrc="https://www.youtube.com/watch?v=4VUBzeUuksM" */}
      {/*   thumbnailSrc="https://img.youtube.com/vi/4VUBzeUuksM/maxresdefault.jpg" */}
      {/*   thumbnailAlt="Hero Video" */}
      {/* /> */}
    </div>
  );
};

export default LandingPageVideoSection;
