import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import {
  RefreshCw,
  Search,
  Pencil,
  Wand2,
  Loader2,
  Sparkles,
  BookOpen,
  Paperclip,
  StarsIcon,
  X,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { api } from '@/lib/axios';
import { FlowTypeSelector } from './components/FlowTypeSelector';
import {
  SubmitSection,
  type SubmitSectionRef,
} from './components/SubmitSection';
import { useObjectiveGeneration } from './hooks/useObjectiveGeneration';
import { useProjectCreation } from './hooks/useProjectCreation';
import { PromptSuggestions } from './components/PromptSuggestions';
import { cn } from '@/lib/utils';
import posthog from 'posthog-js';
import { POSTHOG_EVENTS } from '@/mixpanel/events';
import { type Platform } from './types';
import { useEnhancePrompt } from '@/hooks/useEnhancePrompt';
import { ReadOnlyBadge } from '@/components/ui/read-only-badge';
import { Button } from '@/components/ui/button';
import type { Attachment } from 'ai';
import { v4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { PreviewAttachment } from '@/components/workspace/preview-attachment';
import { KnowledgeBase } from '../ws/Header/KnowledgeBase';
import IntegrationsButton from '../workspace/IntegrationButton';
import { INTEGRATIONS_LIST } from '../workspace/multimodal-input';
import { ScreensModal } from '@/components/ScreensModal';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { WebScreen } from '@/store/useInspirationStore';
import { useWebappStore } from '@/store/useWebappData';
import { debounce } from 'lodash';
import { ImagePreviewModal } from '../ui/image-preview-modal';
import { useAuth, useClerk, useUser } from '@clerk/nextjs';
import { PricingPopup } from '../ui/pricing-popup';
import { useQueryState } from 'nuqs';
import type { FlowType } from './types';
import { useLocalStorage } from 'usehooks-ts';

interface ExtendedAttachment extends Attachment {
  id: string;
}

interface DashboardInputProps {
  platform: Platform;
  designSystem?: string;
  userEmail?: string;
  flowType: FlowType;
  onObjectiveGenerated?: (value: boolean) => void;
  shouldRegenerateObjective?: boolean;
  isReadOnly?: boolean;
}

// Add EnhanceOption type if not already defined
type EnhanceOption = 'creative' | 'professional' | 'detailed';

// Add this constant at the top of the file with other constants
const SUPPORTED_IMAGE_FORMATS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
};

export const DashboardInput = forwardRef<
  { hasContent: () => boolean },
  DashboardInputProps
>(
  (
    {
      platform,
      designSystem,
      userEmail,
      flowType,
      onObjectiveGenerated,
      shouldRegenerateObjective,
      isReadOnly = false,
    },
    ref,
  ) => {
    const router = useRouter();

    const [prompt, setPrompt] = useLocalStorage('dashboard-input-prompt', '');

    const { isSignedIn } = useUser();

    const { openSignIn } = useClerk();

    const [projectId, setProjectId] = useState<string | null>(null);
    const [isPolling, setIsPolling] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const submitButtonRef = useRef<SubmitSectionRef>(null);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { enhanceState, handleEnhancePrompt } = useEnhancePrompt({
      textareaRef,
      setPrompt,
    });

    // const [integrations, setIntegrations] = useState<string[]>([]);

    const integrationsFF = true;

    const {
      objective,
      isGenerating,
      generateObjective,
      setObjective,
      words,
      isEditing,
      setIsEditing,
    } = useObjectiveGeneration({ platform, flowType });

    const { isSubmitting, error, createProject } = useProjectCreation({
      platform,
      flowType,
      userEmail,
      onSubscriptionLimitHit: error => {
        setSubscriptionLimits({
          limits: error.limits,
          upgrade: error.upgrade,
        });
        setShowPricingPopup(true);
      },
    });

    const shouldUseWebappFlow =
      platform === 'web' && flowType === 'single-screen';
    const shouldUseMobileFlow =
      platform === 'mobile' && flowType === 'single-screen';
    const isNewWeb = platform === 'sexy-web';
    const shouldUseInterviewFlow = shouldUseWebappFlow || shouldUseMobileFlow;

    const containerRef = useRef<HTMLDivElement>(null);

    // Add height calculation function
    const [isInputExpanded, setIsInputExpanded] = useState(false);

    const adjustInputHeight = useCallback(() => {
      if (!textareaRef.current) return;

      // Reset height to get the correct scrollHeight
      textareaRef.current.style.height = '48px';
      const scrollHeight = textareaRef.current.scrollHeight;

      // Update input expansion state
      setIsInputExpanded(scrollHeight > 30);

      // Set new height with limits
      const newHeight = Math.min(Math.max(48, scrollHeight), 200);
      textareaRef.current.style.height = `${newHeight}px`;
    }, []);

    useImperativeHandle(ref, () => ({
      hasContent: () => Boolean(prompt.trim() || objective),
    }));

    useEffect(() => {
      if (
        shouldRegenerateObjective === true &&
        prompt.trim() &&
        (objective || words.length > 0)
      ) {
        setIsExpanded(true);
        generateObjective(prompt).then(() => {
          // Signal back to parent that regeneration is complete
          onObjectiveGenerated?.(true); // Keep it true to maintain the alert dialog behavior
        });
      }
    }, [shouldRegenerateObjective, platform, prompt]); // Remove objective and words.length from dependencies

    useEffect(() => {
      onObjectiveGenerated?.(Boolean(objective || words.length > 0));
    }, [objective, words, onObjectiveGenerated]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setPrompt(e.target.value);
      adjustInputHeight();
    };

    const handleKeyDown = async (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      if (e.key === 'Enter') {
        e.preventDefault();

        if (e.shiftKey) {
          // Handle new line with Shift+Enter for prompt
          const { selectionStart, selectionEnd } = e.currentTarget;
          const newValue =
            prompt.slice(0, selectionStart) + '\n' + prompt.slice(selectionEnd);
          setPrompt(newValue);

          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.selectionStart = selectionStart + 1;
              textareaRef.current.selectionEnd = selectionStart + 1;
              adjustInputHeight();
            }
          }, 0);
        } else if (prompt.trim()) {
          submitButtonRef.current?.triggerClick();
          // if (!hasGeneratedOnce) {
          //   // First enter: Generate objective
          //   setIsExpanded(true);
          //   await generateObjective(prompt);
          //   setHasGeneratedOnce(true);
          // } else if (!isGenerating && !isSubmitting) {
          //   // Second enter: Use the exposed triggerClick method
          //   submitButtonRef.current?.triggerClick();
          // }
        }
      }
    };

    useEffect(() => {
      adjustInputHeight();
    }, [prompt, adjustInputHeight]);

    // Polling logic for interview questions
    useEffect(() => {
      let intervalId: NodeJS.Timeout;

      const pollQuestions = async () => {
        if (!projectId || !shouldUseInterviewFlow) return;

        try {
          if (shouldUseWebappFlow) {
            const response = await api.post('/webappv2/getWebappById', {
              webappId: projectId,
            });

            if (response.data?.data?.screens?.[0]?.questions?.length > 0) {
              router.push(
                `https://web.getcreatr.xyz/web/loading-singleScreen/${projectId}`,
              );
            }
          } else {
            const response = await api.post('/projects/getProj', {
              projectId: projectId,
            });

            if (response.data?.status === 2) {
              router.push(`/loading-screen/${projectId}`);
            }
          }
        } catch (error) {
          console.error('Polling error:', error);
          setIsPolling(false);
          enqueueSnackbar('Failed to fetch interview questions', {
            variant: 'error',
          });
        }
      };

      if (isPolling && projectId && shouldUseInterviewFlow) {
        intervalId = setInterval(pollQuestions, 3000);
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [
      isPolling,
      projectId,
      shouldUseWebappFlow,
      shouldUseMobileFlow,
      shouldUseInterviewFlow,
      router,
    ]);

    const { getToken } = useAuth();

    const handlePaste = async (
      e: React.ClipboardEvent<HTMLTextAreaElement>,
    ) => {
      // Get clipboard items
      const items = e.clipboardData?.items;
      if (!items) return;

      // Find image items
      const imageItems = Array.from(items).filter(
        item => item.type.indexOf('image') !== -1,
      );

      if (imageItems.length === 0) return;

      // Prevent default paste for images
      e.preventDefault();

      // Add items to upload queue
      const fileNames = imageItems.map(
        (_, index) => `pasted-image-${index + 1}.png`,
      );
      setUploadQueue(prev => [...prev, ...fileNames]);

      try {
        const userToken = await getToken();
        // Convert clipboard items to files and create FormData
        const formData = new FormData();

        imageItems.forEach((item, index) => {
          const file = item.getAsFile();
          if (!file) return;

          // Create a new file with a proper name
          const newFile = new File([file], `pasted-image-${index + 1}.png`, {
            type: file.type,
          });
          formData.append('files', newFile);
        });

        // Upload the files
        const response = await api.post('/api/web/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.data.success) {
          const uploadedFiles = Array.isArray(response.data.data)
            ? response.data.data
            : [response.data.data];

          const newAttachments = uploadedFiles.map(fileData => ({
            url: fileData.url,
            name: fileData.pathname,
            contentType: fileData.contentType,
            id: v4(),
          }));

          setAttachments(currentAttachments => [
            ...currentAttachments,
            ...newAttachments,
          ]);
        }
      } catch (error: any) {
        console.error('Error uploading pasted files!', error);
        enqueueSnackbar(
          error.response?.data?.error?.message ||
          error.response?.message ||
          'Failed to upload pasted files',
        );
      } finally {
        setUploadQueue(prev => prev.filter(name => !fileNames.includes(name)));
      }
    };

    const handleGenerate = async () => {
      if (!prompt.trim()) return;
      setIsExpanded(true);
      await generateObjective(prompt);
    };

    const handleSubmit = async () => {
      if (!isSignedIn) {
        openSignIn({
          withSignUp: true,
          forceRedirectUrl: `/`,
          appearance: {
            layout: {
              socialButtonsPlacement: 'bottom',
              socialButtonsVariant: 'blockButton',
              unsafe_disableDevelopmentModeWarnings: true,
            },
            elements: {
              formButtonPrimary:
                'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
              card: '!shadow-none border-none ',
              afterButtonsNonLogin: 'hidden',
              footer: 'hidden',
              cardBox: '!shadow-none',
              modalContent: 'h-screen !items-center',
              modalBackdrop: '!items-center',
            },
          },
        });

        return;
      }
      if (prompt.trim() === '') {
        return;
      }
      try {
        const result = await createProject({
          prompt,
          objective,
          projectName: 'Project',
          isPublic: true,
          isInitialSubmit: true,
          knowledgeBase: knowledge,
          attachments: attachments.map(({ id, ...rest }) => rest),
          integrations: integrations.map(integration => ({
            id: integration,
            ...INTEGRATIONS_LIST.find(i => i.id === integration),
          })),
        });

        if (result?.success) {
          // Clear attachments after successful submission
          setAttachments([]);
          // Clear the stored prompt after successful submission
          setPrompt('');

          if (result.data?.id) {
            setProjectId(result.data.id);

            if (shouldUseInterviewFlow) {
              setIsPolling(true);
            } else {
              router.push(`/loading-screen/${result.data.id}`);
            }
          }
        } else {
          // Error is already handled by the hook (shows toast)
          setIsPolling(false);
          setProjectId(null);
        }
      } catch (error) {
        console.error('Submit error:', error);
        setIsPolling(false);
        setProjectId(null);
      }
    };

    const handlePromptSelect = useCallback((selectedPrompt: string) => {
      setPrompt(selectedPrompt);
      // Focus the textarea after selecting a prompt
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, []);

    const controlsHeight = '32px'; // This matches the height of our buttons

    // Add this effect to handle auto-scrolling
    useEffect(() => {
      if (containerRef.current && isGenerating) {
        const container = containerRef.current;
        container.scrollTop = container.scrollHeight;
      }
    }, [words, isGenerating]); // Scroll when words update or while generating

    const [knowledge, setKnowledge] = useState('');

    const [attachments, setAttachments] = useState<Array<ExtendedAttachment>>(
      [],
    );
    const [uploadQueue, setUploadQueue] = useState<Array<string>>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    // Add state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const inspirationsFF = useFeatureFlagEnabled('inspirations');

    const {
      isDraggingFile,
      setIsDraggingFile,
      activeIntegrations: integrations,
      setActiveIntegrations: setIntegrations,
    } = useWebappStore();

    // Update the error toast message
    const showErrorToast = useCallback(
      debounce(
        () => {
          enqueueSnackbar('Only JPEG, PNG and WebP images are allowed', {
            variant: 'error',
          });
        },
        1000,
        { leading: true, trailing: false },
      ),
      [],
    );

    const handleFileChange = async (files: File[]) => {
      setUploadQueue(files.map(file => file.name));

      try {
        const userToken = await getToken();
        const formData = new FormData();
        files.forEach(file => {
          formData.append('files', file);
        });

        const response = await api.post('/api/web/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.data.success) {
          const uploadedFiles = Array.isArray(response.data.data)
            ? response.data.data
            : [response.data.data];

          const newAttachments = uploadedFiles.map(fileData => ({
            url: fileData.url,
            name: fileData.pathname,
            contentType: fileData.contentType,
            id: v4(),
          }));

          setAttachments(currentAttachments => [
            ...currentAttachments,
            ...newAttachments,
          ]);
        }
      } catch (error: any) {
        console.error('Error uploading files!', error);
        enqueueSnackbar(
          error.response?.data?.message ||
          error.response?.data?.error?.message ||
          'Failed to upload files',
          { variant: 'error' },
        );
      } finally {
        setUploadQueue([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };

    const handleInputFileChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const files = Array.from(event.target.files || []);
      handleFileChange(files);
    };

    // Update the useDropzone configuration
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: handleFileChange,
      accept: SUPPORTED_IMAGE_FORMATS,
      multiple: true,
      noClick: true,
      preventDropOnDocument: false,
      noDrag: false,
      onDragEnter: e => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggingFile(true);
      },
      onDragLeave: e => {
        e.preventDefault();
        e.stopPropagation();
        if (
          !e.relatedTarget ||
          !e.currentTarget.contains(e.relatedTarget as Node)
        ) {
          setIsDraggingFile(false);
        }
      },
      onDragOver: e => {
        e.preventDefault();
        e.stopPropagation();
      },
      validator: file => {
        const isSupportedFormat = Object.keys(SUPPORTED_IMAGE_FORMATS).includes(
          file.type,
        );
        if (!isSupportedFormat) {
          showErrorToast();
          return {
            code: 'file-invalid-type',
            message: 'Only JPEG, PNG and WebP images are allowed',
          };
        }
        return null;
      },
    });

    // Add document-level drag event listeners
    useEffect(() => {
      const handleDragEnter = (e: DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(true);
      };

      const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        // Check if we're leaving the document entirely
        if (
          e.clientX <= 0 ||
          e.clientY <= 0 ||
          e.clientX >= window.innerWidth ||
          e.clientY >= window.innerHeight
        ) {
          setIsDraggingFile(false);
        }
      };

      const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(false);
      };

      const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
      };

      // Add a dragend event listener to handle cases where the drag operation ends
      const handleDragEnd = (e: DragEvent) => {
        e.preventDefault();
        setIsDraggingFile(false);
      };

      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragleave', handleDragLeave);
      document.addEventListener('drop', handleDrop);
      document.addEventListener('dragover', handleDragOver);
      document.addEventListener('dragend', handleDragEnd);

      return () => {
        document.removeEventListener('dragenter', handleDragEnter);
        document.removeEventListener('dragleave', handleDragLeave);
        document.removeEventListener('drop', handleDrop);
        document.removeEventListener('dragover', handleDragOver);
        document.removeEventListener('dragend', handleDragEnd);
      };
    }, [setIsDraggingFile]);

    const handleInsertScreens = (screens: WebScreen[]) => {
      const newAttachments = screens.map(screen => ({
        url: screen.screenUrl,
        name: `${screen.appName}-screenshot.png`,
        contentType: 'image/png',
        id: v4(),
      }));

      setAttachments(prev => [...prev, ...newAttachments]);
    };

    // Add this useEffect to reset state when platform changes
    useEffect(() => {
      setObjective('');
      setIsExpanded(false);
      setAttachments([]);
      setKnowledge('');
      setIntegrations([]);

      // Reset textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = '48px';
      }

      // Notify parent about content change
    }, [platform]);

    const [previewImage, setPreviewImage] = useState<{
      url: string;
      name: string;
    } | null>(null);

    // Add state for pricing popup
    const [showPricingPopup, setShowPricingPopup] = useState(false);
    const [subscriptionLimits, setSubscriptionLimits] = useState<any>(null);

    return (
      <div className="relative mx-auto w-full space-y-4 sm:max-w-3xl">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="relative">
            <div
              className={cn(
                'absolute inset-0 z-50 transform transition-all duration-300 ease-out',
                isDraggingFile
                  ? 'opacity-100'
                  : 'pointer-events-none opacity-0',
              )}
            >
              <div className="mb-0 h-full w-full rounded-lg border-2 border-dashed border-zinc-300 bg-zinc-100/90 p-6">
                <div className="flex h-full flex-col items-center justify-center gap-3">
                  <div className="flex items-center justify-center gap-2">
                    <svg
                      className="h-5 w-5 rotate-[-4deg] text-zinc-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="text-base font-medium text-zinc-700">
                      Drop files here to add to chat
                    </span>
                    <svg
                      className="h-5 w-5 rotate-[4deg] text-zinc-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </div>
                  <span className="text-sm text-zinc-600">
                    Max 20 files per chat at 30 MB each
                  </span>
                </div>
              </div>
            </div>

            <motion.div
              className={cn(
                'relative overflow-hidden rounded-2xl border border-zinc-200 bg-white dark:border-zinc-800 dark:bg-zinc-950',
                'transform transition-all duration-300 ease-out',
              )}
            >
              {/* File Preview */}
              {(attachments.length > 0 || uploadQueue.length > 0) && (
                <div className="flex flex-wrap items-end gap-2 rounded-t-lg border-x border-t bg-white p-2">
                  {attachments.map((attachment: ExtendedAttachment) => (
                    <div
                      key={attachment.id}
                      className="group relative flex flex-col gap-1"
                    >
                      <div
                        className="relative h-20 w-20 cursor-pointer overflow-hidden rounded-lg border border-gray-200"
                        onClick={() =>
                          setPreviewImage({
                            url: attachment.url,
                            name: attachment.name,
                          })
                        }
                      >
                        <img
                          src={attachment.url}
                          alt={attachment.name}
                          className="h-full w-full object-cover"
                        />
                        <button
                          onClick={e => {
                            e.stopPropagation(); // Prevent triggering the parent onClick
                            setAttachments(currentAttachments =>
                              currentAttachments.filter(
                                a => a.id !== attachment.id,
                              ),
                            );
                          }}
                          className="absolute right-1 top-1 rounded-full bg-gray-900/60 p-1 opacity-0 transition-opacity group-hover:opacity-100 hover:bg-gray-900/80"
                        >
                          <X className="h-3 w-3 text-white" />
                        </button>
                      </div>
                      <span className="max-w-[80px] truncate text-[10px] text-gray-500">
                        {attachment.name}
                      </span>
                    </div>
                  ))}
                  {uploadQueue.map(filename => (
                    <PreviewAttachment
                      key={filename}
                      attachment={{
                        url: '',
                        name: filename,
                        contentType: '',
                      }}
                      isUploading={true}
                    />
                  ))}
                </div>
              )}

              {/* Input Area */}
              <div className="relative flex w-full items-start px-3">
                {isReadOnly && (
                  <div className="absolute right-4 top-3 z-10">
                    <ReadOnlyBadge />
                  </div>
                )}

                {/* Main input container */}
                <div className="flex w-full flex-1 items-start gap-2">
                  <div
                    className={cn(
                      'relative flex-1',
                      isInputExpanded ? '' : 'h-12',
                    )}
                  >
                    <textarea
                      ref={textareaRef}
                      disabled={isReadOnly}
                      rows={1}
                      placeholder="Describe your project..."
                      value={prompt}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      onPaste={handlePaste}
                      className={cn(
                        'w-full bg-transparent text-zinc-900 placeholder-zinc-500 outline-none',
                        'resize-none dark:text-zinc-50 dark:placeholder-zinc-400',
                        isInputExpanded ? 'py-3' : 'h-12 py-3',
                        isReadOnly && 'cursor-not-allowed opacity-60',
                      )}
                      style={{
                        minHeight: isInputExpanded ? '80px' : 'inherit',
                      }}
                    />
                    {integrations.length > 0 && (
                      <div className="mb-1">
                        {integrations.map(integration => {
                          const integrationItem = INTEGRATIONS_LIST.find(
                            i => i.id === integration,
                          );

                          // If no matching integration is found, don't render anything
                          if (!integrationItem) return null;

                          return (
                            <div
                              key={integrationItem.id}
                              className="flex w-fit items-center gap-2 rounded-lg border bg-zinc-50 p-1 pl-2 text-[10px] shadow-sm dark:bg-zinc-800"
                            >
                              <span className="text-xs">
                                {integrationItem.name || integration}
                              </span>
                              <button
                                onClick={() =>
                                  setIntegrations(
                                    integrations.filter(i => i !== integration),
                                  )
                                }
                                className="text-zinc-500 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-200"
                                aria-label={`Remove ${integrationItem.name || integration}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <line x1="18" y1="6" x2="6" y2="18" />
                                  <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="mb-2 flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            posthog.capture(POSTHOG_EVENTS.ENHANCE_PROMPT, {
                              prompt: prompt,
                              type: 'Dashboard',
                            });
                            if (!isSignedIn) {
                              openSignIn({
                                withSignUp: true,
                                forceRedirectUrl: `/`,
                                appearance: {
                                  layout: {
                                    socialButtonsPlacement: 'bottom',
                                    socialButtonsVariant: 'blockButton',
                                    unsafe_disableDevelopmentModeWarnings: true,
                                  },
                                  elements: {
                                    formButtonPrimary:
                                      'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                                    card: '!shadow-none border-none ',
                                    afterButtonsNonLogin: 'hidden',
                                    footer: 'hidden',
                                    cardBox: '!shadow-none',
                                    modalContent: 'h-screen !items-center',
                                    modalBackdrop: '!items-center',
                                  },
                                },
                              });

                              return;
                            }
                            return handleEnhancePrompt({ isGeneration: true });
                          }}
                          disabled={
                            isReadOnly ||
                            prompt.trim() === '' ||
                            enhanceState === 'enhancing'
                          }
                          className={cn(
                            'flex items-center gap-1.5 rounded-md p-[7px] text-gray-600',
                            'hover:bg-zinc-100 dark:hover:bg-zinc-800',
                            'disabled:cursor-not-allowed disabled:opacity-50',
                          )}
                        >
                          {enhanceState === 'enhancing' ? (
                            <>
                              <Loader2 className="h-4 w-4 animate-spin" />
                              <span className="text-xs">Enhancing...</span>
                            </>
                          ) : enhanceState === 'enhanced' ? (
                            <>
                              <Sparkles className="h-4 w-4" />
                              <span className="text-xs">Enhanced</span>
                            </>
                          ) : (
                            <>
                              <Wand2 className="h-4 w-4" />
                              <span className="text-xs">Enhance</span>
                            </>
                          )}
                        </button>

                        <button
                          onClick={() => {
                            if (!isSignedIn) {
                              openSignIn({
                                withSignUp: true,
                                forceRedirectUrl: `/`,
                                appearance: {
                                  layout: {
                                    socialButtonsPlacement: 'bottom',
                                    socialButtonsVariant: 'blockButton',
                                    unsafe_disableDevelopmentModeWarnings: true,
                                  },
                                  elements: {
                                    formButtonPrimary:
                                      'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                                    card: '!shadow-none border-none ',
                                    afterButtonsNonLogin: 'hidden',
                                    footer: 'hidden',
                                    cardBox: '!shadow-none',
                                    modalContent: 'h-screen !items-center',
                                    modalBackdrop: '!items-center',
                                  },
                                },
                              });

                              return;
                            }

                            fileInputRef.current?.click();
                          }}
                          disabled={isReadOnly}
                          className={cn(
                            'flex items-center gap-1.5 rounded-md p-[7px] text-gray-600',
                            'hover:bg-zinc-100 dark:hover:bg-zinc-800',
                            'disabled:cursor-not-allowed disabled:opacity-50',
                          )}
                        >
                          <Paperclip className="h-4 w-4" />
                          <span className="text-xs">Attach</span>
                        </button>

                        {inspirationsFF && platform !== 'component' && (
                          <button
                            onClick={() => {
                              if (!isSignedIn) {
                                openSignIn({
                                  withSignUp: true,
                                  forceRedirectUrl: `/`,
                                  appearance: {
                                    layout: {
                                      socialButtonsPlacement: 'bottom',
                                      socialButtonsVariant: 'blockButton',
                                      unsafe_disableDevelopmentModeWarnings:
                                        true,
                                    },
                                    elements: {
                                      formButtonPrimary:
                                        'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                                      card: '!shadow-none border-none ',
                                      afterButtonsNonLogin: 'hidden',
                                      footer: 'hidden',
                                      cardBox: '!shadow-none',
                                      modalContent: 'h-screen !items-center',
                                      modalBackdrop: '!items-center',
                                    },
                                  },
                                });

                                return;
                              }
                              setIsModalOpen(true);
                            }}
                            disabled={isReadOnly}
                            className={cn(
                              'flex items-center gap-1.5 rounded-md p-[7px] text-gray-600',
                              'hover:bg-zinc-100 dark:hover:bg-zinc-800',
                              'disabled:cursor-not-allowed disabled:opacity-50',
                            )}
                          >
                            <StarsIcon className="h-4 w-4" />
                            <span className="text-xs">Inspirations</span>
                          </button>
                        )}

                        {/* <KnowledgeBase
                        initialKnowledge={knowledge}
                        onUpdate={(newKnowledge) => setKnowledge(newKnowledge)}
                        shouldSaveToAPI={false}
                        trigger={
                          <button
                            className={cn(
                              "mb-2 flex items-center gap-1.5 rounded-md p-[7px] text-gray-600",
                              "hover:bg-zinc-100 dark:hover:bg-zinc-800",
                              "disabled:cursor-not-allowed disabled:opacity-50",
                            )}
                          >
                            <BookOpen className="h-4 w-4" />
                            <span className="text-xs">Knowledge</span>
                          </button>
                        }
                      /> */}
                        {integrationsFF && platform !== 'component' && (
                          <IntegrationsButton
                            className="mt-0 flex items-center gap-1.5 rounded-md border-none bg-transparent p-[7px] text-gray-600"
                            integrations={integrations}
                            setIntegrations={setIntegrations}
                          />
                        )}
                      </div>
                      <SubmitSection
                        objective={objective}
                        ref={submitButtonRef}
                        isGenerating={isGenerating}
                        isSubmitting={isSubmitting}
                        isPolling={isPolling || enhanceState === 'enhancing'}
                        prompt={prompt}
                        onGenerate={handleGenerate}
                        onSubmit={handleSubmit}
                        platform={platform}
                        flowType={flowType}
                        variant="standalone"
                      />
                    </div>
                  </div>

                  {/* Controls container */}
                  {/* <div className="flex flex-shrink-0 items-center gap-2 py-2">
                    <FlowTypeSelector
                      flowType={flowType}
                      onFlowTypeChange={handleFlowTypeChange}
                      platform={platform}
                      variant="embedded"
                      isNewWeb={isNewWeb}
                    />
                  </div> */}
                </div>
              </div>

              {/* Expanded Content */}
              <AnimatePresence>
                {isExpanded && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {/* Objective Section */}
                    <div className="border-t border-zinc-200 p-4 dark:border-zinc-800">
                      <div className="mb-1 flex items-center justify-between">
                        <span className="text-sm font-medium text-zinc-900 dark:text-zinc-50">
                          Generated Objective
                        </span>
                        <div className="flex items-center gap-1">
                          <Pencil
                            className={cn(
                              'h-4 w-4 cursor-pointer text-black',
                              isGenerating && 'opacity-50',
                            )}
                            onClick={() => !isGenerating && setIsEditing(true)}
                          />
                          <button
                            onClick={handleGenerate}
                            disabled={isGenerating}
                            className={cn(
                              'rounded-full p-1 hover:bg-zinc-100 dark:hover:bg-zinc-800',
                              isGenerating && 'opacity-50',
                            )}
                          >
                            <RefreshCw
                              className={cn(
                                'h-4 w-4 text-black',
                                isGenerating && 'animate-spin',
                              )}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="relative">
                        <div
                          ref={containerRef}
                          className="max-h-[200px] w-full overflow-y-auto scroll-smooth"
                        >
                          <AnimatePresence mode="wait">
                            {(isGenerating || words.length > 0) &&
                              !isEditing ? (
                              <motion.div
                                key="display"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="cursor-text space-y-1"
                                onClick={() =>
                                  !isGenerating && setIsEditing(true)
                                }
                              >
                                {words.map((word, idx) =>
                                  word.isNewline ? (
                                    <br key={`newline-${idx}`} />
                                  ) : (
                                    <motion.span
                                      key={`${word.text}-${idx}`}
                                      initial={{
                                        opacity: 0,
                                        filter: 'blur(8px)',
                                      }}
                                      animate={{
                                        opacity: 1,
                                        filter: 'blur(0px)',
                                      }}
                                      transition={{
                                        duration: 0.15,
                                        ease: 'easeOut',
                                      }}
                                      className="mr-1 inline-block text-sm text-zinc-900 dark:text-zinc-50"
                                    >
                                      {word.text}
                                    </motion.span>
                                  ),
                                )}
                                {isGenerating && (
                                  <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    className="inline-block h-4 w-0.5 animate-pulse bg-zinc-900 dark:bg-zinc-50"
                                  />
                                )}
                              </motion.div>
                            ) : (
                              <motion.div
                                key="edit"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                              >
                                <textarea
                                  value={objective}
                                  onChange={e => setObjective(e.target.value)}
                                  onBlur={() => setIsEditing(false)}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                      if (e.shiftKey) {
                                        setObjective(objective + '\n');
                                      } else if (
                                        !isGenerating &&
                                        !isSubmitting
                                      ) {
                                        submitButtonRef.current?.triggerClick();
                                      }
                                    }
                                  }}
                                  autoFocus={isEditing}
                                  rows={objective.split('\n').length}
                                  className={cn(
                                    'max-h-[200px] w-full resize-none overflow-y-auto bg-transparent text-sm text-zinc-900 outline-none',
                                    'transition-opacity duration-200',
                                    '[scrollbar-color:rgb(161_161_170)_transparent] [scrollbar-width:thin]',
                                    'dark:text-zinc-50 dark:[scrollbar-color:rgb(82_82_91)_transparent]',
                                    '[&::-webkit-scrollbar-thumb]:rounded-[3px] [&::-webkit-scrollbar-thumb]:bg-zinc-300',
                                    'dark:[&::-webkit-scrollbar-thumb]:bg-zinc-700 [&::-webkit-scrollbar-track]:bg-transparent',
                                    '[&::-webkit-scrollbar]:w-[6px]',
                                  )}
                                />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                        {isGenerating && (
                          <div className="pointer-events-none absolute inset-0 flex items-center justify-center bg-white/50 dark:bg-zinc-950/50">
                            {/* <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-zinc-900 dark:border-white" /> */}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Controls Section */}
                    <div className="mt-6 flex items-center justify-between border-t border-zinc-200 p-4 dark:border-zinc-800">
                      <span className="text-sm text-zinc-500 dark:text-zinc-400">
                        Use Shift+Return for a new line
                      </span>
                      <SubmitSection
                        objective={objective}
                        isGenerating={isGenerating}
                        isSubmitting={isSubmitting}
                        isPolling={isPolling || enhanceState === 'enhancing'}
                        prompt={prompt}
                        onGenerate={handleGenerate}
                        onSubmit={handleSubmit}
                        platform={platform}
                        flowType={flowType}
                        variant="embedded"
                        ref={submitButtonRef}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>

          {/* Fixed height container for controls */}
          <div
            className={cn(
              'flex w-full justify-end pt-2',
              isDraggingFile && 'pointer-events-none opacity-0',
            )}
          ></div>

          {/* Suggestions */}
          <AnimatePresence>
            {!isExpanded && (
              <motion.div
                initial={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className="mt-4"
              >
                <PromptSuggestions
                  platform={platform}
                  flowType={flowType}
                  onSelect={handlePromptSelect}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Add this hidden input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleInputFileChange}
          multiple
          accept="image/*"
        />

        {/* Add ScreensModal */}
        {inspirationsFF && (
          <ScreensModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onInsert={handleInsertScreens}
          />
        )}

        <ImagePreviewModal
          isOpen={previewImage !== null}
          onClose={() => setPreviewImage(null)}
          imageUrl={previewImage?.url || ''}
          imageName={previewImage?.name || ''}
        />
      </div>
    );
  },
);

DashboardInput.displayName = 'DashboardInput';

export default DashboardInput;
